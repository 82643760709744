<template>
  <div>
    <div class="row mb-2 align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("dataSets.selectDataStructure") }}
      </label>
      <div class="col">
        <v-autocomplete
          v-model="stage.config.model"
          class="form-control"
          :items="dataStructures"
          item-value="classname"
          :item-text="ds => `${ds.label} - v.${ds.version}`"
          :menu-props="{ offsetY: true }"
          :placeholder="$t('dataSets.noDataStructureSelected')"
          :readonly="readOnly"
          :no-data-text="$t('dataSets.noDataAvailable')"
          @change="onDataStructureChange"
        />
      </div>
    </div>

    <div
      v-if="stage.parentId !== undefined && stage.parentId !== null"
      class="row align-items-center"
    >
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("dataSets.dataStructureAlias") }}
      </label>
      <div class="col py-1">
        <input
          v-model.trim="stage.config.alias"
          type="text"
          class="form-control"
          :class="
            stage.config.alias == null || stage.config.alias.trim().length === 0
              ? 'is-invalid'
              : 'is-valid'
          "
          @input="validateData"
        />
      </div>
    </div>

    <div class="row">
      <div class="col py-1">
        <h5>{{ $t("dataSets.fields") }}</h5>
      </div>
    </div>

    <div v-for="(field, i) in stage.config.fields" :key="i" class="row">
      <div class="col py-1">
        <div class="input-group">
          <input
            v-model="stage.config.fields[i].alias"
            class="form-control"
            :placeholder="$t('dataSets.alias')"
            style="max-width: 200px"
            @input="validateData"
          />
          <v-autocomplete
            v-model="fieldsLabel[i]"
            :items="dataStructureFieldsFlat"
            class="form-control mt-0"
            :class="
              dataStructureFieldsFlat.includes(fieldsLabel[i])
                ? 'is-valid'
                : 'is-invalid'
            "
            :placeholder="$t('dataSets.fieldPlaceholder')"
            :menu-props="{ maxHeight: 400, maxWidth: 300 }"
            :readonly="readOnly"
            @keydown="onFieldKeyDown"
            @change="onFieldChange(field, i)"
          >
            <template #item="{ item, on, attrs }">
              <v-list-item
                v-bind="attrs"
                @mouseenter="onFieldHover(item)"
                v-on="on"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <div
            v-if="!readOnly"
            v-b-tooltip.top.noninteractive="$t('dataSets.removeFieldHelp')"
            class="input-group-append cursor-pointer"
            @click="removeField(i)"
          >
            <span class="input-group-text">
              <i class="fal fa-trash icon-lg" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        selectedDataStructure &&
          (stage.config.fields === undefined ||
            stage.config.fields.length === 0)
      "
      class="row"
    >
      <div class="col py-0 pl-3">
        <span class="text-danger">
          {{ $t("dataSets.noFieldsSelected") }}
        </span>
      </div>
    </div>

    <div v-if="!readOnly" class="row">
      <div class="col py-1 pl-3">
        <a
          v-if="selectedDataStructure"
          :key="componentKey"
          style="color: #b5b5c3"
          @click="addField"
        >
          {{ $t("dataSets.addField") }}
        </a>
        <span v-else class="text-danger">
          {{ $t("dataSets.noDataStructureSelected") }}
        </span>
      </div>
    </div>

    <template v-for="queryStage in joinQueries">
      <div :key="queryStage.config.model" class="row">
        <div class="col pb-1">
          <h5 class="mb-0">{{ queryStage.config.alias }}</h5>
        </div>
      </div>
      <div
        v-for="(field, i) in queryStage.config.fields"
        :key="queryStage.config.model + i"
        class="row"
      >
        <div class="col py-1 pl-6">
          {{ field.alias + " - " + field.field }}
        </div>
      </div>
    </template>

    <!-- Field data menu -->
    <v-menu
      v-model="showFieldData"
      :position-x="fieldMenuPositionX"
      :position-y="fieldMenuPositionY"
    >
      <v-list class="p-0" style="max-width: 20vw">
        <v-list-item dense>
          <v-list-item-title>
            {{ $t("dataSets.fieldData") }}
          </v-list-item-title>
        </v-list-item>
        <template v-for="(data, i) in fieldData">
          <v-divider v-show="i !== 0" :key="`${i}-divider`" class="my-0" />
          <v-list-item :key="i" class="field-data" dense>
            <v-list-item-subtitle v-if="data === null">
              {{ $t("dataSets.nullText") }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>{{ data }}</v-list-item-subtitle>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <!-- Field data menu -->
  </div>
</template>

<script>
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import {
  SET_SELECTED_DATA_STRUCTURE,
  STAGE_TYPE_JOIN,
  STAGE_TYPE_QUERY
} from "@/core/services/store/dataSets.module";
import DataPipelineValidation from "@/components/Projects/DataSets/dataPipelineValidation";
import { mapGetters } from "vuex";

export default {
  props: {
    stage: {
      type: Object,
      default: null
    },
    updateKey: {
      type: Number,
      default: 0
    },
    dataStructures: {
      type: Array,
      default: () => []
    },
    dataStructureService: DataStructures,
    readOnly: {
      type: Boolean,
      default: false
    },
    pipelineStages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedDataStructureData: [],

      // the 'componentKey' is needed to update the ui when 'this.stage.config.fields' changed
      componentKey: 0,
      showFieldData: true,
      fieldData: "",
      fieldMenuPositionX: 0,
      fieldMenuPositionY: 0,
      fieldsLabel: [],
      structureFields: ["fieldset", "collection"],
      isBusy: 0
    };
  },
  computed: {
    ...mapGetters(["selectedDataStructure"]),
    dataStructureFields: function() {
      if (
        this.selectedDataStructure === undefined ||
        this.selectedDataStructure === null
      ) {
        return [];
      }
      return this.selectedDataStructure.fields;
    },
    dataStructureFieldsFlat: function() {
      if (
        this.selectedDataStructure === undefined ||
        this.selectedDataStructure === null ||
        this.selectedDataStructure.fields === undefined ||
        this.selectedDataStructure.fields === null
      ) {
        return [];
      }
      return this.selectedDataStructure.fields.map(
        f => f.full_name + " - " + f.type
      );
    },
    joinQueries: function() {
      if (this.stage.parentId !== undefined && this.stage.parentId !== null) {
        return [];
      }
      let stages = [];
      this.pipelineStages.forEach(s => {
        if (s.type !== STAGE_TYPE_JOIN) {
          return;
        }
        const queryStage = s.config.subPipeline.find(
          stage => stage.type === STAGE_TYPE_QUERY
        );
        if (!queryStage) {
          return;
        }
        stages.push(queryStage);
      });
      return stages;
    }
  },
  watch: {
    selectedDataStructure: function() {
      this.setFieldsLabel();
    }
  },
  mounted() {
    this.initData();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    this.$emit("updateBusyState", this.isBusy * -1);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    initData() {
      if (this.stage.config.model) {
        if (
          this.stage.parentId !== undefined &&
          this.stage.parentId !== null &&
          this.stage.config.alias === ""
        ) {
          this.stage.config.alias = this.stage.config.model;
        }
        this.loadDataStructureData();
      }
      this.setFieldsLabel();
      this.validateData();
    },
    setFieldsLabel() {
      this.stage.config.fields.forEach(f => {
        const field = this.dataStructureFields.find(
          fi => fi.full_name === f.field
        );
        if (field) {
          this.fieldsLabel.push(field.full_name + " - " + field.type);
        }
      });
    },
    async setSelectedDataStructure() {
      await this.$store.dispatch(SET_SELECTED_DATA_STRUCTURE, {
        classname: this.stage.config.model,
        dataStructureService: this.dataStructureService,
        queryStage: this.stage
      });
    },
    loadDataStructureData() {
      if (!this.selectedDataStructure) return;
      this.selectedDataStructureData = [];
      this.dataStructureService
        .getDataStructureData(this.selectedDataStructure.id)
        .then(response => {
          this.selectedDataStructureData = response.data.data;
        });
    },

    async onDataStructureChange() {
      this.stage.config.fields = [];
      this.fieldsLabel = [];
      await this.setSelectedDataStructure();
      this.initData();
    },
    onFieldChange(field, i) {
      field.field =
        this.fieldsLabel[i] !== null
          ? this.fieldsLabel[i].split(" - ")[0]
          : null;
      if (
        field.alias !== undefined &&
        field.alias !== null &&
        field.alias.trim().length !== 0 &&
        !this.dataStructureFieldsFlat.includes(field.alias)
      ) {
        this.validateData();
        return;
      }
      field.alias = field.field.replace(".", "_");
      this.validateData();
    },
    onFieldKeyDown(e) {
      if (e.key === "Escape") {
        this.showFieldData = false;
      }
    },
    onFieldHover(fieldName) {
      const path = fieldName.split(" - ")[0].split(".");
      this.fieldData = this.selectedDataStructureData.map(function(item) {
        let data = item[path[0]];
        for (let i = 1; i < path.length; i++) {
          if (data === null) {
            return null;
          }
          data = data[path[i]];
        }
        return data;
      });

      this.setFieldDataMenuPosition();
      this.showFieldData = true;
    },
    setFieldDataMenuPosition() {
      const elements = document.getElementsByClassName(
        "v-menu__content theme--light menuable__content__active v-autocomplete__content"
      );
      if (elements.length < 1) return;
      const offset = elements[0].getBoundingClientRect();
      this.fieldMenuPositionX = offset.x + offset.width + 3;
      this.fieldMenuPositionY = offset.y;
    },
    removeField(index) {
      this.stage.config.fields.splice(index, 1);
      this.fieldsLabel.splice(index, 1);
      this.componentKey++;
      this.validateData();
    },
    addField() {
      this.stage.config.fields.push({
        alias: "",
        field: null
      });
      this.fieldsLabel.push("");
      this.componentKey++;
    },
    onResize() {
      this.setFieldDataMenuPosition();
    },

    validateData() {
      const validResponse = DataPipelineValidation.validateQueryStage(
        this.stage
      );

      this.$emit("validate", validResponse.isValid, this.stage);
    }
  }
};
</script>

<style lang="scss">
.input-group-append:hover .input-group-text i {
  color: #ff3554;
}

.field-data.v-list-item--dense {
  min-height: 30px;
}
</style>
