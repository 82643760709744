<template>
  <div>
    <div class="row align-items-center mt-0">
      <div class="col-xs-1 ml-auto">
        <button
          class="btn btn-primary"
          style="white-space: nowrap"
          @click="addParameter"
        >
          {{ $t("dataSets.addParameter") }}
        </button>
      </div>
    </div>
    <TableWrapper :meta="meta" :disableSearch="true">
      <b-table
        responsive
        :items="fields"
        :fields="columns"
        class="dataTable table table-head-custom pt-5"
        :show-empty="true"
        :empty-text="$t('table.noRecords')"
      >
        <template #cell(type)="data">
          <div v-if="data.item.type !== 'config'">
            <i
              v-b-tooltip.top.noninteractive="$t('dataSets.parameter')"
              class="fa-regular fa-brackets-curly fa-xl"
            />
          </div>
          <div v-else>
            <i
              v-b-tooltip.top.noninteractive="$t('dataSets.config')"
              class="fa-regular fa-gears fa-xl"
            />
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="d-inline-flex float-right">
            <button
              v-b-tooltip.top.noninteractive="$t('general.edit')"
              class="btn btn-icon btn-light btn-sm mr-1"
              @click.left="editParameter(data)"
            >
              <i class="fal fa-pen"/>
            </button>
            <div v-if="data.item.type !== 'config'">
              <button
                v-b-tooltip.top.noninteractive="$t('general.delete')"
                class="btn btn-icon btn-light btn-sm mr-1"
                @click="removeParameter(data)"
              >
                <i class="fal fa-trash"/>
              </button>
            </div>
          </div>
        </template>
      </b-table>
    </TableWrapper>
    <b-modal
      ref="addParameterValueModal"
      body-class="add-config-value"
      hide-footer
      hide-header
    >
      <ParameterValueModal
        :full-width="true"
        :return-to-route="false"
        :type="type"
        :key-index="key"
        :value="value"
        @parameter-value-create-saved="parameterValueSaved"
        @parameter-value-create-updated="parameterValueUpdated"
        @parameter-value-create-cancel="$refs['addParameterValueModal'].hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import ParameterValueModal from "./ParameterValueModal";
import {LOAD_PARAMETER_VALUE, REMOVE_PARAMETER_VALUE, UPDATE_PARAMETER_VALUE} from "@/core/services/store/dataSets.module";
import Swal from "sweetalert2";
import {mapGetters} from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper";

// import {bus} from "@/main";

export default {
  name: "Parameter",
  components: {
    ParameterValueModal,
    TableWrapper
  },
  props: {
    initialLimit: {
      type: Number,
      default: 50
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      limit: 50,
      customLimit: "",
      limits: [15, 25, 50, 100],
      value: "",
      refreshKey: 0,
      meta: {},
      isBusy: false,
      key: null
    };
  },
  computed: {
    ...mapGetters(["parameterValues", "configValues"]),
    fields: function () {
      this.refreshKey;
      let fields = [];
      this.parameterValues.forEach(vars => {
        let variable = this.setVariable(vars, "parameter");
        if (fields.indexOf(variable)) {
          fields.push(variable)
        }
      })
      this.configValues.forEach(vars => {
        let variable = this.setVariable(vars, "config");
        if (fields.indexOf(variable)) {
          fields.push(variable)
        }
      })
      return fields;
    },
    columns() {
      return [
        {
          key: "name",
          label: this.$t("config.label"),
          sortable: true,
          thStyle: {width: "300px"}
        },
        {
          key: "type",
          label: this.$t("config.type"),
          sortable: true,
          thStyle: {width: "90px"}
        },
        {
          key: "value",
          label: this.$t("config.value"),
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          thStyle: {"min-width": "140px", "float":"right"}
        }
      ];
    },
    type: function () {
      return "text";
    }
  },
  methods: {
    setVariable(vars, type) {
      let name = (type === "config") ? vars.label : vars.name
      return {
        name: name,
        type: type,
        value: vars.value
      }
    },
    addParameter() {
      this.key = null;
      this.$refs["addParameterValueModal"].show();
    },
    removeParameter(data) {
      let  toDelete = this.parameterValues.find(value => value.value === data.item.value && value.name === data.item.name)
      Swal.fire({
        title: this.$t("dataSets.deleteParameter", {
          name: data.item.name
        }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        showCloseButton: true,
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.confirm")
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(REMOVE_PARAMETER_VALUE, 0);
          this.data.splice(this.parameterValues.indexOf(toDelete), 1);
        }
      });
    },
    editParameter(data) {
      this.refreshKey++;
      if (data.item.type === "parameter") {
        let index = this.parameterValues.find(
          value => value.value === data.item.value &&
            value.name === data.item.name
        )
        this.key = this.parameterValues.indexOf(index);
        this.$refs["addParameterValueModal"].show();
        this.$forceUpdate();
        return;
      }
      this.redirectToConfigEditor(data.index);
    },
    redirectToConfigEditor(index) {
      const redirect = this.$store.state.dataSets.configValues[index - 1];

      let routeData = this.$router.resolve({
        name: "projectSettingsConfigDetails",
        params: {configId: redirect.id}
      });
      window.open(routeData.href, "_blank");
      this.$forceUpdate();
    },
    addParameterToTable(parameter) {
      const element = {
        name: parameter.name,
        type: parameter.type,
        value: parameter.value
      };

      this.data.push(element);
      this.$store.dispatch(LOAD_PARAMETER_VALUE, element);
    },
    parameterValueSaved(parameterValue) {
      this.addParameterToTable(parameterValue);
      this.$refs["addParameterValueModal"].hide();
    },
    parameterValueUpdated(updatedParameterValue, i) {
      this.$store.dispatch(UPDATE_PARAMETER_VALUE, [updatedParameterValue, i]);
      this.refreshKey++;
      this.$refs["addParameterValueModal"].hide();
    }
  }
};
</script>

<style lang="scss">
.modal-body.add-config-value {
  padding: 0;
}
</style>
