<template>
  <div ref="wrapper">
    <div class="d-flex flex-nowrap mt-2">
      <div style="flex-grow: 1; max-width: 100%">
        <button class="btn btn-primary btn-hover" @click="showAddDataSet">
          <i class="fal fa-plus"></i>
          {{ field.label }}
        </button>
      </div>
    </div>
    <b-modal
        v-model="showModal"
        :title="$t('dataSets.createDataSet')"
        :cancel-title="$t('general.cancel')"
        :ok-title="$t('dataSets.create')"
        centered
        @ok="createDataSet"
    >
      <input
          ref="createDataPipelineName"
          v-model="newDataPipeline.name"
          class="form-control"
          :placeholder="$t('dataSets.name')"
          @keydown="onNewDataPipelineNameKeydown"
      />
    </b-modal>
    <b-modal
        size="xl"
        v-model="showEditor"
        :title="$t('dataSets.createDataSet')"
        :ok-title="$t('dataSets.create')"
        hide-footer
        centered
    >
      <Editor :isModal="true" @closeModal="showEditor = false; showModal = false" />
    </b-modal>
  </div>
</template>

<script>
import StageFactory from "@/components/Projects/DataSets/stageFactory";
import {SET_DATA_PIPELINE_CREATED} from "@/core/services/store/dataSets.module";
import Editor from "@/components/Projects/DataSets/Editor/Index"

export default {
  props: {
    field: {
      type: Object
    }
  },
  components: { Editor },
  data() {
    return {
      showModal: false,
      showEditor: false,
      newDataPipeline: {
        name: ""
      }
    }
  },
  methods: {
    createDataSet() {
      if (this.newDataPipeline.name.trim().length === 0) {
        return;
      }
      this.$store.dispatch(SET_DATA_PIPELINE_CREATED, this.newDataPipeline);
      this.showEditor = true;
    },
    showAddDataSet() {
      this.newDataPipeline = {
        name: "",
        active: 1,
        pipelineStages: [StageFactory.makeQueryStage(0)]
      };
      this.showModal = true;
    },
    onNewDataPipelineNameKeydown(event) {
      if (event.key !== "Enter") {
        return;
      }
      this.createDataSet();
    },
  }
};
</script>
