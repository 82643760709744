var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mb-2 align-items-center"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("dataSets.selectDataStructure"))+" ")]),_c('div',{staticClass:"col"},[_c('v-autocomplete',{staticClass:"form-control",attrs:{"items":_vm.dataStructures,"item-value":"classname","item-text":ds => `${ds.label} - v.${ds.version}`,"menu-props":{ offsetY: true },"placeholder":_vm.$t('dataSets.noDataStructureSelected'),"readonly":_vm.readOnly,"no-data-text":_vm.$t('dataSets.noDataAvailable')},on:{"change":_vm.onDataStructureChange},model:{value:(_vm.stage.config.model),callback:function ($$v) {_vm.$set(_vm.stage.config, "model", $$v)},expression:"stage.config.model"}})],1)]),(_vm.stage.parentId !== undefined && _vm.stage.parentId !== null)?_c('div',{staticClass:"row align-items-center"},[_c('label',{staticClass:"col-xl-3 col-lg-3 col-form-label"},[_vm._v(" "+_vm._s(_vm.$t("dataSets.dataStructureAlias"))+" ")]),_c('div',{staticClass:"col py-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.stage.config.alias),expression:"stage.config.alias",modifiers:{"trim":true}}],staticClass:"form-control",class:_vm.stage.config.alias == null || _vm.stage.config.alias.trim().length === 0
            ? 'is-invalid'
            : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.stage.config.alias)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.stage.config, "alias", $event.target.value.trim())},_vm.validateData],"blur":function($event){return _vm.$forceUpdate()}}})])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col py-1"},[_c('h5',[_vm._v(_vm._s(_vm.$t("dataSets.fields")))])])]),_vm._l((_vm.stage.config.fields),function(field,i){return _c('div',{key:i,staticClass:"row"},[_c('div',{staticClass:"col py-1"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.stage.config.fields[i].alias),expression:"stage.config.fields[i].alias"}],staticClass:"form-control",staticStyle:{"max-width":"200px"},attrs:{"placeholder":_vm.$t('dataSets.alias')},domProps:{"value":(_vm.stage.config.fields[i].alias)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.stage.config.fields[i], "alias", $event.target.value)},_vm.validateData]}}),_c('v-autocomplete',{staticClass:"form-control mt-0",class:_vm.dataStructureFieldsFlat.includes(_vm.fieldsLabel[i])
              ? 'is-valid'
              : 'is-invalid',attrs:{"items":_vm.dataStructureFieldsFlat,"placeholder":_vm.$t('dataSets.fieldPlaceholder'),"menu-props":{ maxHeight: 400, maxWidth: 300 },"readonly":_vm.readOnly},on:{"keydown":_vm.onFieldKeyDown,"change":function($event){return _vm.onFieldChange(field, i)}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({on:{"mouseenter":function($event){return _vm.onFieldHover(item)}}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item)+" ")])],1)],1)]}}],null,true),model:{value:(_vm.fieldsLabel[i]),callback:function ($$v) {_vm.$set(_vm.fieldsLabel, i, $$v)},expression:"fieldsLabel[i]"}}),(!_vm.readOnly)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive",value:(_vm.$t('dataSets.removeFieldHelp')),expression:"$t('dataSets.removeFieldHelp')",modifiers:{"top":true,"noninteractive":true}}],staticClass:"input-group-append cursor-pointer",on:{"click":function($event){return _vm.removeField(i)}}},[_vm._m(0,true)]):_vm._e()],1)])])}),(
      _vm.selectedDataStructure &&
        (_vm.stage.config.fields === undefined ||
          _vm.stage.config.fields.length === 0)
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col py-0 pl-3"},[_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("dataSets.noFieldsSelected"))+" ")])])]):_vm._e(),(!_vm.readOnly)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col py-1 pl-3"},[(_vm.selectedDataStructure)?_c('a',{key:_vm.componentKey,staticStyle:{"color":"#b5b5c3"},on:{"click":_vm.addField}},[_vm._v(" "+_vm._s(_vm.$t("dataSets.addField"))+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("dataSets.noDataStructureSelected"))+" ")])])]):_vm._e(),_vm._l((_vm.joinQueries),function(queryStage){return [_c('div',{key:queryStage.config.model,staticClass:"row"},[_c('div',{staticClass:"col pb-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(queryStage.config.alias))])])]),_vm._l((queryStage.config.fields),function(field,i){return _c('div',{key:queryStage.config.model + i,staticClass:"row"},[_c('div',{staticClass:"col py-1 pl-6"},[_vm._v(" "+_vm._s(field.alias + " - " + field.field)+" ")])])})]}),_c('v-menu',{attrs:{"position-x":_vm.fieldMenuPositionX,"position-y":_vm.fieldMenuPositionY},model:{value:(_vm.showFieldData),callback:function ($$v) {_vm.showFieldData=$$v},expression:"showFieldData"}},[_c('v-list',{staticClass:"p-0",staticStyle:{"max-width":"20vw"}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("dataSets.fieldData"))+" ")])],1),_vm._l((_vm.fieldData),function(data,i){return [_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(i !== 0),expression:"i !== 0"}],key:`${i}-divider`,staticClass:"my-0"}),_c('v-list-item',{key:i,staticClass:"field-data",attrs:{"dense":""}},[(data === null)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("dataSets.nullText"))+" ")]):_c('v-list-item-subtitle',[_vm._v(_vm._s(data))])],1)]})],2)],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fal fa-trash icon-lg"})])
}]

export { render, staticRenderFns }