<template>
  <div>
    <div class="row">
      <div class="col py-1 mt-2">
        <h5>{{ $t("dataSets.aggregations") }}</h5>
      </div>
    </div>

    <div
      v-for="(aggregation, i) in stage.config.aggregations"
      :key="aggregation.aggregation + i"
      class="row"
    >
      <div class="col py-1">
        <AggregationInput
          :key="`${i}-aggregation-input`"
          :aggregation="aggregation"
          :data-structure="dataStructure"
          :sub-data-structures="subDataStructures"
          :aggregations="aggregations"
          :read-only="readOnly"
          @removeAggregation="removeAggregation(i)"
          @validate="onValidate"
        />
      </div>
    </div>

    <div v-if="!readOnly" class="row">
      <div class="col py-1 pl-5">
        <a
          v-if="dataStructure"
          :key="componentKey"
          style="color: #b5b5c3"
          @click="addAggregation"
        >
          {{ $t("dataSets.addTransformer") }}
        </a>
        <span v-else class="text-danger">
          {{ $t("dataSets.noDataStructureSelected") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AggregationInput from "@/components/Projects/DataSets/Editor/StageConfig/Components/AggregationInput";
import { mapGetters } from "vuex";

export default {
  components: {
    AggregationInput
  },
  props: {
    stage: {
      type: Object,
      default: null
    },
    subDataStructures: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentKey: 0
    };
  },
  computed: {
    ...mapGetters({
      dataStructure: "selectedDataStructure",
      aggregations: "dataPipelineAggregations"
    })
  },
  methods: {
    addAggregation() {
      this.stage.config.aggregations.push({
        alias: "",
        fields: {},
        aggregation: "",
        useArray: false,
        valid: false
      });
      this.componentKey++;
    },
    removeAggregation(index) {
      this.stage.config.aggregations.splice(index, 1);
      this.componentKey++;
    },
    onValidate() {
      let isValid = true;
      for (const aggregation of this.stage.config.aggregations) {
        if (!aggregation.valid) {
          isValid = false;
          break;
        }
      }
      this.stage.isValid = isValid;
      this.$emit("validate", isValid, this.stage);
    }
  }
};
</script>
