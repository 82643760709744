<template>
  <div class="d-flex yedi-aggregation-control justify-content-between pl-0">
    <div
      class="w-100 d-flex"
      :class="
        aggregationIsValid ? 'aggregation-is-valid' : 'aggregation-is-invalid'
      "
    >
      <input
        v-model="aggregation.alias"
        class="yedi-aggregation-input mx-2"
        :placeholder="$t('dataSets.alias')"
        style="max-width: 150px"
        @input="validateAggregation"
      />
      <v-autocomplete
        ref="aggregation"
        v-model="aggregation.aggregation"
        class="form-control border-0"
        style="max-width: 150px"
        :items="aggregations"
        item-text="name"
        item-value="name"
        :placeholder="$t('dataSets.selectAggregation')"
        :menu-props="{ offsetY: true, maxHeight: 350 }"
        hide-details
        dense
        @change="onChangeAggregation"
      />
      <template v-if="selectedAggregation !== undefined">
        <template v-for="(field, fieldKey) in selectedAggregation.fields">
          <v-autocomplete
            v-if="field.type === 'array' && field.arrayType"
            :key="fieldKey"
            v-model="selectedFields[field.name]"
            :placeholder="field.name"
            :items="
              field.arrayType ? fieldsFiltered(field.arrayType) : fieldsComplete
            "
            item-value="full_name"
            item-text="full_name"
            class="form-control border-0"
            dense
            multiple
            small-chips
            hide-details
            deletable-chips
            :persistent-placeholder="true"
            :menu-props="{ maxHeight: '600' }"
            @change="onChangeCombo($event, field.name)"
          >
            <template #item="{ item, on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} - {{ item.type }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>

          <autosize-input
            v-else
            :key="field.name"
            ref="field"
            v-model="aggregation.fields[field.name]"
            input-class="yedi-aggregation-input ml-3 w-100 mt-1"
            style="width: 100%"
            :placeholder="field.name"
            @focus="onFocusInput($event, fieldMenu, field)"
            @blur="onBlurInput(fieldMenu)"
            @change="
              validateAggregation();
              setFieldFilter(field.name);
            "
          />
        </template>
      </template>
    </div>

    <div v-if="!readOnly" class="d-flex">
      <button
        v-b-tooltip.top.noninteractive="$t('dataSets.clearAggregationHelp')"
        class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-aggregation-input-button"
        @click="clearAggregation()"
      >
        <i class="fal fa-xmark icon-lg pr-0" />
      </button>
      <button
        v-b-tooltip.top.noninteractive="$t('dataSets.removeAggregationHelp')"
        class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-aggregation-input-button"
        @click="removeAggregation()"
      >
        <i class="fal fa-trash icon-lg pr-0" />
      </button>
    </div>

    <!-- Field menu -->
    <v-menu
      v-if="dataStructureFieldsFiltered.length > 0"
      v-model="fieldMenu.shown"
      z-index="2000"
      :position-x="fieldMenu.x"
      :position-y="fieldMenu.y"
      :open-on-click="false"
      :close-on-click="false"
      :max-height="500"
    >
      <v-list class="p-0" style="min-width: 200px">
        <template v-for="(ds, i) in dataStructureFieldsFiltered">
          <v-list-item :key="i" class="operator-group" dense>
            <v-list-item-subtitle>{{ ds.label }} </v-list-item-subtitle>
          </v-list-item>

          <v-divider :key="`${i}-divider-ds`" class="my-0" />

          <v-list-item
            v-for="field in ds.fields"
            :key="`${ds.label}-${field.full_name}`"
            class="operator-data"
            dense
            @click="selectField(field.alias ? field.alias : field.full_name)"
          >
            <v-list-item-title
              >{{
                `${field.alias ? field.alias : field.full_name} - ${field.type}`
              }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <!-- Field menu -->
  </div>
</template>

<script>
import AutosizeInput from "vue-autosize-input";

export default {
  components: {
    AutosizeInput
  },
  props: {
    aggregation: {
      type: Object,
      default: null
    },
    dataStructure: {
      type: Object,
      default: null
    },
    subDataStructures: {
      type: Array,
      default: () => []
    },
    aggregations: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      aggregationIsValid: false,
      selectedFields: {},
      fieldMenu: {
        shown: false,
        x: 0,
        y: 0,
        fieldKey: null,
        fieldType: null,
        searchTerm: ""
      },
      aggregationMenu: {
        shown: false,
        x: 0,
        y: 0
      }
    };
  },
  computed: {
    selectedAggregation: function () {
      return this.aggregations.find(
        a => a.name === this.aggregation.aggregation
      );
    },
    fieldsComplete: function () {
      let fields = [];

      let dataStructures = [this.dataStructure];
      if (this.subDataStructures !== null) {
        dataStructures.push(...this.subDataStructures);
      }

      dataStructures.forEach(ds => {
        fields.push({
          header: ds.classname
        });
        fields.push(
          ...ds.fields.map(f => {
            return { full_name: f.full_name, name: f.name, type: f.type };
          })
        );
      });
      return fields;
    },
    dataStructureFieldsFiltered() {
      let dataStructures = [this.dataStructure];
      let filteredDataStructures = [];
      if (this.subDataStructures !== null) {
        dataStructures.push(...this.subDataStructures);
      }
      const fieldTypes =
        this.fieldMenu.fieldType === "number"
          ? ["int", "float"]
          : [this.fieldMenu.fieldType];

      dataStructures.forEach(ds => {
        if (ds.fields === undefined) return;
        let fieldsFiltered = ds.fields
          .filter(
            f =>
              (fieldTypes.includes(f.type) || fieldTypes.includes("all")) &&
              f.name.includes(this.fieldMenu.searchTerm)
          )
          .map(f => {
            return {
              full_name: f.full_name,
              name: f.name,
              type: f.type
            };
          });
        if (fieldsFiltered.length > 0) {
          filteredDataStructures.push({
            classname: ds.classname,
            fields: fieldsFiltered
          });
        }
      });
      return filteredDataStructures;
    }
  },
  watch: {
    selectedAggregation: function (aggregation) {
      this.selectedFields = {};
      if (
        aggregation &&
        aggregation.fields !== undefined &&
        aggregation.fields !== null
      ) {
        aggregation.fields.forEach(f => {
          this.selectedFields[f.name] = f.type === "array" ? [] : "";
        });
      }
    }
  },
  mounted() {
    this.initData();
    if (
      this.aggregation.aggregation === null ||
      this.aggregation.aggregation.length === 0
    ) {
      this.$nextTick().then(() => {
        this.$refs.aggregation.focus();
        this.$refs.aggregation.isMenuActive = true;
      });
    }
    this.validateAggregation();
  },
  methods: {
    initData() {
      Object.keys(this.aggregation.fields).forEach(fieldKey => {
        if (
          this.aggregation.fields[fieldKey] === null ||
          typeof this.aggregation.fields[fieldKey] !== "object"
        ) {
          return;
        }
        this.selectedFields[fieldKey] = this.aggregation.fields[fieldKey].map(
          f => {
            const fieldName = f.substring(1);
            if (this.dataStructure.fields !== undefined) {
              let field = this.dataStructure.fields.find(
                f => f.full_name === fieldName
              );
              if (field) {
                return field;
              }
            }

            for (const ds of this.subDataStructures) {
              if (ds.fields !== undefined) {
                let field = ds.fields.find(f => f.full_name === fieldName);
                if (field) {
                  return field;
                }
              }
            }
          }
        );
      });
    },
    fieldsFiltered(type) {
      let fields = [];

      let dataStructures = [this.dataStructure];
      if (this.subDataStructures !== null) {
        dataStructures.push(...this.subDataStructures);
      }

      let types = [type];
      if (type === "string") {
        types = ["string", "text"];
      }

      dataStructures.forEach(ds => {
        if (ds.fields === undefined) return;
        let fieldsFiltered = ds.fields
          .filter(f => types.includes(f.type))
          .map(f => {
            return { full_name: f.full_name, name: f.name, type: f.type };
          });
        if (fieldsFiltered.length === 0) {
          return;
        }

        fields.push({
          header: ds.classname
        });
        fields.push(...fieldsFiltered);
      });
      return fields;
    },
    onFocusInput(e, menu, field = null) {
      if (this.readOnly) return;

      const offset = e.target.getBoundingClientRect();

      if (field !== null) {
        menu.fieldKey = field.name;
        menu.fieldType = field.type;
        menu.searchTerm = this.aggregation.fields[field.name];
      }

      menu.x = offset.x - 4;
      menu.y = offset.y + offset.height;
      menu.shown = true;
    },
    onBlurInput(menu) {
      setTimeout(() => {
        menu.shown = false;
      }, 100);
    },
    onChangeCombo(e, fieldName) {
      this.aggregation.fields[fieldName] = e.map(f =>
        "$".concat(typeof f === "object" ? f.name : f)
      );
      this.validateAggregation();
    },
    removeField(item, fieldName) {
      const index = this.aggregation.fields[fieldName].indexOf(item);
      if (index > -1) {
        this.aggregation.fields[fieldName].splice(index, 1);
      }
    },

    selectField(field) {
      this.aggregation.fields[this.fieldMenu.fieldKey] = "$".concat(field);

      this.validateAggregation();
    },
    onChangeAggregation() {
      this.aggregation.fields = {};
      this.aggregation.useArray = this.selectedAggregation.useArray ?? false;

      if (
        this.aggregation.alias === undefined ||
        this.aggregation.alias.length === 0
      ) {
        this.selectedAggregation.name.startsWith("$")
          ? (this.aggregation.alias = this.selectedAggregation.name.substring(
              1,
              this.selectedAggregation.name.length
            ))
          : (this.aggregation.alias = this.selectedAggregation.name);
      }

      if (
        this.selectedAggregation.fields === undefined ||
        this.selectedAggregation.fields === null
      )
        return;
      this.selectedAggregation.fields.forEach(field => {
        this.aggregation.fields[field.name] = field.type === "array" ? [] : "";
      });
    },

    validateAggregation() {
      let valid =
        this.aggregation.aggregation !== null &&
        this.aggregation.aggregation.length > 0 &&
        this.aggregation.alias.length > 0;
      if (!valid) {
        this.aggregation.valid = false;
        this.aggregationIsValid = false;
        this.$emit("validate");
        return;
      }

      for (const fieldName in this.aggregation.fields) {
        const fieldVal = this.aggregation.fields[fieldName];
        const field = this.selectedAggregation.fields.find(
          f => f.name === fieldName
        );
        if (!field) {
          valid = false;
          break;
        }
        if (fieldVal === null || fieldVal.length <= 0) {
          valid = false;
          break;
        }

        if (
          field.minValues !== undefined &&
          field.minValues > fieldVal.length
        ) {
          valid = false;
          break;
        }

        if (!isNaN(fieldVal)) {
          this.aggregation.fields[fieldName] = parseInt(fieldVal);
        }
      }

      this.aggregation.valid = valid;
      this.aggregationIsValid = valid;

      this.$emit("validate");
    },
    setFieldFilter(fieldName) {
      this.fieldMenu.searchTerm = this.aggregation.fields[fieldName];
    },
    clearAggregation() {
      this.aggregation.alias = "";
      this.aggregation.aggregation = "";
      this.aggregation.fields = {};
      this.validateAggregation();
      this.$nextTick().then(() => {
        if (this.$refs.aggregation === undefined) return;
        this.$refs.aggregation.focus();
        this.$refs.aggregation.isMenuActive = true;
      });
    },
    removeAggregation() {
      this.$emit("removeAggregation");
    }
  }
};
</script>

<style lang="scss">
.aggregation-aggregation {
  color: rgb(128, 95, 150) !important;
}

.v-text-field--outlined fieldset {
  border: none !important;
}

.v-chip .v-chip__content {
  padding: 10px !important;
}

.v-chip.v-size--small {
  padding-top: 0 !important;
  margin-top: 5px !important;
}

.aggregation-is-invalid {
  outline: 1px solid rgb(227, 91, 100);
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  z-index: 1;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.aggregation-is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.yedi-aggregation-control {
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}

.yedi-aggregation-input-button {
  border-left: 1px solid #e4e6ef !important;
  border-radius: 0 !important;
  background-color: #f3f6f9 !important;
}

.yedi-aggregation-input {
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 0 8px;
}

.yedi-aggregation-input:focus {
  outline: none;
}

/* https://github.com/vuetifyjs/vuetify/issues/11553 */
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit;
}
</style>
