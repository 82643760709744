<template>
  <div>
    <div class="row align-items-center text-center mt-1">
      <div class="col-5 pb-0">
        <span v-if="mainDataStructure" class="text-muted">
          {{ mainDataStructure.label }}
        </span>
      </div>
      <div class="col-auto px-0 pb-0"></div>
      <div class="col-5 pb-0">
        <span v-if="dataStructure" class="text-muted">
          {{ dataStructure.label }}
        </span>
      </div>
    </div>
    <div class="row align-items-center mt-0">
      <div class="col-5">
        <v-autocomplete
          v-if="mainDataStructure"
          v-model="stage.config.localField"
          :items="mainDataStructureFields"
          :item-text="f => `${f.full_name} - ${f.type}`"
          item-value="full_name"
          class="form-control"
          :class="
            mainDataStructureFieldsFlat.includes(stage.config.localField)
              ? 'is-valid'
              : 'is-invalid'
          "
          :placeholder="$t('dataSets.localField')"
          :menu-props="{ maxHeight: 400, maxWidth: 300 }"
          dense
          @change="validate"
        >
          <template #prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ mainDataStructure.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template #item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.full_name }} - {{ item.type }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <span v-else class="text-danger">
          {{ $t("dataSets.noDataStructureSelected") }}
        </span>
      </div>

      <div class="col-auto px-0">
        <span>=</span>
      </div>

      <div class="col-5">
        <v-autocomplete
          v-if="dataStructure"
          v-model="stage.config.foreignField"
          :items="dataStructureFields"
          :item-text="f => `${f.full_name} - ${f.type}`"
          item-value="full_name"
          class="form-control"
          :class="
            dataStructureFieldsFlat.includes(stage.config.foreignField)
              ? 'is-valid'
              : 'is-invalid'
          "
          :placeholder="$t('dataSets.foreignField')"
          :menu-props="{ maxHeight: 400, maxWidth: 300 }"
          dense
          @change="validate"
        >
          <template #prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ dataStructure.label }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template #item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.full_name }} - {{ item.type }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <span v-else class="text-danger">
          {{ $t("dataSets.noDataStructureSelected") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DataPipelineValidation from "@/components/Projects/DataSets/dataPipelineValidation";
import { mapGetters } from "vuex";

export default {
  props: {
    stage: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      dataStructure: "selectedDataStructure",
      mainDataStructure: "mainDataStructure"
    }),
    dataStructureFields: function () {
      if (
        this.dataStructure === undefined ||
        this.dataStructure === null ||
        this.dataStructure.fields === undefined
      ) {
        return [];
      }
      return this.dataStructure.fields;
    },
    mainDataStructureFields: function () {
      if (
        this.mainDataStructure === undefined ||
        this.mainDataStructure === null
      ) {
        return [];
      }
      return this.mainDataStructure.fields;
    },
    dataStructureFieldsFlat: function () {
      return this.dataStructureFields.map(f => f.full_name);
    },
    mainDataStructureFieldsFlat: function () {
      return this.mainDataStructureFields.map(f => f.full_name);
    }
  },
  mounted() {
    this.validate();
  },
  methods: {
    validate() {
      const validResponse = DataPipelineValidation.validateOnStage(this.stage);

      this.$emit("validate", validResponse.isValid, this.stage);
    }
  }
};
</script>

<style scoped></style>
