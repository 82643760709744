<template>
  <div>
    <div class="row align-items-center mt-0">
      <div class="col-auto">
        <span class="text-muted text-no-wrap">{{ $t("dataSets.limit") }}</span>
      </div>
      <div class="col-auto d-flex align-items-center">
        <v-select
          v-model="limit"
          :items="limits"
          :menu-props="{ offsetY: true }"
          class="limit-select"
          hide-details
          dense
          @change="changeLimit(true)"
        >
          <template #selection="{ item }">
            <span v-if="customLimit"> {{ customLimit }}</span>
            <span v-else>{{ item }}</span>
          </template>
          <template #prepend-item>
            <v-list-item class="px-2 pb-2">
              <input
                v-model="customLimit"
                type="number"
                class="form-control limit-input"
                :placeholder="$t('dataSets.customLimit')"
                @input="changeLimit(false)"
              />
            </v-list-item>
          </template>
        </v-select>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table
          responsive
          :items="data"
          :fields="fields"
          class="dataTable table table-head-custom datapipeline-preview"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialLimit: {
      type: Number,
      default: 50
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      limit: 50,
      customLimit: "",
      limits: [15, 25, 50, 100],
      fields: []
    };
  },
  watch: {
    data: {
      deep: true,
      handler: function () {
        this.fields = [];
        if (this.data.length > 0) {
          for (const field in this.data[0]) {
            this.fields.push({ key: field, label: field });
          }
        }
      }
    }
  },
  methods: {
    changeLimit(fromSelect) {
      const limit =
        !fromSelect && this.customLimit.toString().length > 0
          ? this.customLimit
          : this.limit;

      if (fromSelect) {
        this.customLimit = "";
      }
      this.$emit("limitChanged", Number(limit));
    }
  }
};
</script>

<style lang="scss">
.table.table-head-custom {
  &.datapipeline-preview {
    thead {
      th {
        text-transform: none !important;
      }
    }
  }
}
</style>

<style scoped>
.limit-select {
  max-width: 100px;
}
.limit-input {
  width: 84px;
}
</style>
