import Konva from "konva";
import {
  STAGE_TYPE_AGGREGATION,
  STAGE_TYPE_FILTER,
  STAGE_TYPE_JOIN,
  STAGE_TYPE_ON,
  STAGE_TYPE_QUERY
} from "@/core/services/store/dataSets.module";

export function circleScene(context, shape) {
  let width = shape.width();
  let height = shape.height();
  context.beginPath();
  context.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2);
  context.lineWidth = 1;
  context.fillStyle = "#fff";
  context.fill();
  context.fillStrokeShape(shape);
  context.closePath();
}

export function createCircleShape(gridWidth, gridHeight, color) {
  return new Konva.Shape({
    opacity: 1,
    type: "circle",
    width: gridWidth,
    height: gridHeight / 2,
    stroke: color,
    strokeWidth: 1,
    fillPatternRepeat: "no-repeat",
    fillPriority: "pattern",
    sceneFunc: circleScene,
    shadowColor: color,
    shadowBlur: 10,
    shadowOffset: { x: 0, y: 0 },
    shadowOpacity: 0.7,
    shadowEnabled: false
  });
}

export function createCircleShapeIcon(circleShape, iconSize, icon, color) {
  return new Konva.Text({
    x: circleShape.x() + circleShape.width() / 2 - iconSize / 2,
    y: circleShape.y() + circleShape.height() / 2 - iconSize / 2,
    text: icon,
    fontFamily: '"Font Awesome 6 Pro"',
    fontStyle: "bold",
    fill: color,
    fontSize: iconSize
  });
}

export function contentArrowShapeScene(context, s, shape, offset) {
  context.beginPath();
  context.moveTo(
    shape.x() + shape.width() + 2 + offset,
    shape.height() / 2 - 1
  );
  context.lineTo(shape.x() + shape.width() + 16 + offset, 10);
  context.lineTo(shape.x() + shape.width() + 16 + offset, shape.height() - 10);
  context.closePath();
  context.fillStyle = "#F3F6F9";
  context.fillStrokeShape(s);
}

export function createKonvaButton(position, text, color) {
  let button = new Konva.Label({
    x: position.x,
    y: position.y
  });

  button.add(
    new Konva.Tag({
      fill: color,
      lineJoin: "round",
      cornerRadius: 5
    })
  );

  button.add(
    new Konva.Text({
      text: text,
      fill: "white",
      fontSize: 12,
      padding: 11,
      align: "center",
      lineHeight: 0.9
    })
  );

  return button;
}

export function setButtonFill(button, color) {
  button
    .getChildren(c => c.getClassName() === "Tag")
    .forEach(c => {
      c.fill(color);
    });
}

export function getStageIcon(type) {
  switch (type) {
    case STAGE_TYPE_QUERY:
      return "\uf1c0";
    case STAGE_TYPE_JOIN:
      return "\uf248";
    case STAGE_TYPE_FILTER:
      return "\ue17e";
    case STAGE_TYPE_AGGREGATION:
      return "\uf5fd";
    case STAGE_TYPE_ON:
      return "\uf019";
    default:
      return "\uf067";
  }
}
