<template>
  <div>
    <div v-for="(s, i) in stage.config.subPipeline" :key="i">
      <div v-if="i > 0" class="row">
        <div class="col py-1 pb-2">
          <v-divider />
        </div>
      </div>
      <QueryConfig
        v-if="s.type === 0"
        ref="queryConfig"
        :stage="s"
        :data-structures="dataStructures"
        :data-structure-service="dataStructureService"
        class="mb-5"
      />

      <OnConfig
        v-else-if="s.type === 5"
        class="mb-5"
        :stage="s"
        :data-structure="dataStructure"
      />

      <FilterConfig
        v-else-if="s.type === 2"
        :stage="s"
        :data-structure="dataStructure"
        class="mb-5"
      />

      <AggregationConfig
        v-else-if="s.type === 3"
        :stage="s"
        :data-structure="dataStructure"
        class="mb-5"
      />
    </div>
  </div>
</template>

<script>
import AggregationConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/AggregationConfig";
import FilterConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/FilterConfig";
import OnConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/OnConfig";
import QueryConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/QueryConfig";
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import { mapGetters } from "vuex";

export default {
  components: {
    AggregationConfig,
    FilterConfig,
    OnConfig,
    QueryConfig
  },
  props: {
    stage: {
      type: Object,
      default: null
    },
    dataStructures: {
      type: Array,
      default: () => []
    },
    dataStructureService: DataStructures
  },
  computed: {
    ...mapGetters({
      dataStructure: "selectedDataStructure"
    })
  }
};
</script>
