import { generateHash } from "@/components/Tools/helperFunctions";
import {
  STAGE_TYPE_AGGREGATION,
  STAGE_TYPE_FILTER,
  STAGE_TYPE_JOIN,
  STAGE_TYPE_ON,
  STAGE_TYPE_QUERY
} from "@/core/services/store/dataSets.module";

class StageFactory {
  makeQueryStage(orderIndex, parentId = null) {
    const config = {
      model: null,
      fields: []
    };
    if (parentId !== null) {
      config.alias = "";
    }
    return this.makeStage(
      "Query",
      STAGE_TYPE_QUERY,
      orderIndex,
      config,
      parentId
    );
  }

  makeFilterStage(orderIndex, parentId = null) {
    const config = {
      filter: {
        type: "group",
        operator: "and",
        children: [
          {
            type: "filter",
            field: "",
            operator: "",
            value: "",
            valid: false
          }
        ]
      }
    };
    return this.makeStage(
      "Filter",
      STAGE_TYPE_FILTER,
      orderIndex,
      config,
      parentId
    );
  }

  makeAggregationStage(orderIndex, parentId = null) {
    const config = {
      aggregations: []
    };
    return this.makeStage(
      "Transformer",
      STAGE_TYPE_AGGREGATION,
      orderIndex,
      config,
      parentId
    );
  }

  makeJoinStage(orderIndex, parentId = null) {
    const config = {
      subPipeline: []
    };
    return this.makeStage(
      "Join",
      STAGE_TYPE_JOIN,
      orderIndex,
      config,
      parentId
    );
  }

  makeOnStage(orderIndex, parentId = null) {
    const config = {
      localField: "",
      foreignField: ""
    };
    return this.makeStage(
      "Join Bedingung",
      STAGE_TYPE_ON,
      orderIndex,
      config,
      parentId
    );
  }

  makeStage(name, type, orderIndex, config, parentId) {
    if (config === null) {
      config = {};
    }
    config.hash = generateHash(6);
    config.title = name;
    config.description = "";
    return {
      name: name,
      type: type,
      order_index: orderIndex,
      config: config,
      parentId: parentId,
      active: true
    };
  }
}

export default new StageFactory();
