import {
  STAGE_TYPE_AGGREGATION,
  STAGE_TYPE_FILTER,
  STAGE_TYPE_JOIN,
  STAGE_TYPE_ON,
  STAGE_TYPE_QUERY
} from "@/core/services/store/dataSets.module";

class DataPipelineValidation {
  validateDataPipeline(dataPipeline) {
    for (const stage of dataPipeline.pipelineStages) {
      const validResponse = this.validateStage(stage);
      stage.isValid = validResponse.isValid;
      if (!validResponse.isValid) {
        return validResponse;
      }
    }
    return { isValid: true };
  }

  validateStage(stage) {
    let validResponse = { isValid: true };
    if (stage.type === STAGE_TYPE_QUERY) {
      validResponse = this.validateQueryStage(stage);
    } else if (stage.type === STAGE_TYPE_FILTER) {
      validResponse = this.validateFilterStage(stage);
    } else if (stage.type === STAGE_TYPE_AGGREGATION) {
      validResponse = this.validateAggregationStage(stage);
    } else if (stage.type === STAGE_TYPE_JOIN) {
      validResponse = this.validateJoinStage(stage);
    } else if (stage.type === STAGE_TYPE_ON) {
      validResponse = this.validateOnStage(stage);
    }

    if (validResponse.stage === undefined) {
      validResponse.stage = stage;
    }

    return validResponse;
  }

  validateQueryStage(stage) {
    let validResponse = { isValid: true };
    if (stage.config.model === null) {
      validResponse.isValid = false;
      validResponse.errorMessage = "dataSets.noDataStructureSelected";
    } else if (stage.config.fields.length === 0) {
      validResponse.isValid = false;
      validResponse.errorMessage = "dataSets.noFieldSelected";
    } else if (
      stage.parentId !== undefined &&
      stage.parentId !== null &&
      (stage.config.alias === undefined ||
        stage.config.alias.trim().length === 0)
    ) {
      validResponse.isValid = false;
      validResponse.errorMessage = "dataSets.selectAlias";
    } else {
      for (const field of stage.config.fields) {
        if (field.field === null || field.field === "") {
          validResponse.isValid = false;
          validResponse.errorMessage = "dataSets.noFieldSelected";
          return validResponse;
        }
        const fieldsWithSameAlias = stage.config.fields.filter(
          f => f.alias !== null && f.alias.trim() === field.alias.trim()
        );
        if (fieldsWithSameAlias.length > 1) {
          validResponse.isValid = false;
          validResponse.errorMessage = "dataSets.aliasNotUnique";
          return validResponse;
        }
      }
    }

    return validResponse;
  }

  validateFilterStage(stage) {
    return this.validateFilterGroup(stage.config.filter);
  }

  validateFilterGroup(group) {
    for (const element of group.children) {
      if (
        (element.type === "filter" && !element.valid) ||
        (element.type === "group" && !this.validateFilterGroup(element).isValid)
      ) {
        return {
          isValid: false,
          errorMessage: "dataSets.filterIsInvalid"
        };
      }
    }
    return { isValid: true };
  }

  validateAggregationStage(stage) {
    const invalidResponse = {
      isValid: false,
      errorMessage: "dataSets.aggregationIsInvalid"
    };
    for (const aggregation of stage.config.aggregations) {
      if (aggregation.aggregation.trim().length === 0) {
        return invalidResponse;
      } else if (aggregation.alias.trim().length === 0) {
        return invalidResponse;
      }

      /*
            for (const field in aggregation.fields) {
                if (aggregation.fields[field].trim().length === 0) {
                    return invalidResponse;
                }
            }
            */
    }

    return { isValid: true };
  }

  validateJoinStage(stage) {
    if (stage.config.subPipeline.length === 0) {
      return { isValid: false };
    }
    const stagesSorted = stage.config.subPipeline.sort((a, b) => {
      return a.order_index - b.order_index;
    });
    for (const childStage of stagesSorted) {
      const validResponse = this.validateStage(childStage);
      if (!validResponse.isValid) {
        return validResponse;
      }
    }
    return { isValid: true };
  }

  validateOnStage(stage) {
    let validResponse = { isValid: true };
    if (stage.config.localField.trim().length === 0) {
      validResponse.isValid = false;
      validResponse.errorMessage = "dataSets.selectLocalField";
    } else if (stage.config.foreignField.trim().length === 0) {
      validResponse.isValid = false;
      validResponse.errorMessage = "dataSets.selectForeignField";
    }

    return validResponse;
  }
}

export default new DataPipelineValidation();
