<template>
  <div>
    <ContextMenu
      ref="contextMenu"
      :show-add-join="!addMenu.inJoin"
      style="display: none"
      @addJoin="addNewJoin"
      @addFilter="addFilter"
      @addAggregation="addAggregation"
    />

    <div id="stages-container" class="text-danger" />
  </div>
</template>

<script>
import ContextMenu from "@/components/Projects/DataSets/Editor/StageConfig/Components/ContextMenu";
import Konva from "konva";
import $ from "jquery";
import StageFactory from "@/components/Projects/DataSets/stageFactory";
import {
  circleScene,
  createCircleShape,
  createCircleShapeIcon,
  contentArrowShapeScene,
  createKonvaButton,
  setButtonFill,
  getStageIcon
} from "@/components/Projects/DataSets/Editor/stageViewHelpers";
import debounce from "lodash/debounce";
import DataPipelineValidation from "@/components/Projects/DataSets/dataPipelineValidation";

import {
  STAGE_TYPE_QUERY,
  STAGE_TYPE_JOIN,
  STAGE_TYPE_ON
} from "@/core/services/store/dataSets.module";
import Swal from "sweetalert2";

export default {
  components: {
    ContextMenu
  },
  props: {
    pipelineStages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      gridHeight: 100.0,
      gridWidth: 50,
      stage: null,
      layers: {
        main: null,
        lines: null,
        grid: null,
        tmp: null
      },

      nodes: [],
      selectedStage: null,

      mainAddButton: null,
      shadowRectangle: null,
      lastPosition: null,
      lastElementChanged: {
        element: null,
        up: false
      },

      originalPosition: null,

      addMenu: {
        target: null,
        inJoin: false
      },

      iconSize: 14,
      colors: {
        primary: "#FF3553",
        primaryDark: "#F50025",
        black: "#9babbd",
        gray: "#f3f6f9",
        green: "#0BB7AF",
        greenLight: "#1BC5BD",
        blue: "#3b7095"
      }
    };
  },
  mounted() {
    this.init();

    window.addEventListener("resize", this.resize);
  },
  methods: {
    resize: debounce(function () {
      this.init(true);
    }, 200),
    init(isResize = false) {
      let $container = $("#stages-container");

      this.stage = new Konva.Stage({
        container: "stages-container",
        width: $container.width(),
        height: $container.height()
      });

      this.shadowRectangle = new Konva.Circle({
        radius: this.gridWidth / 2,
        x: 25,
        y: 25,
        fill: this.colors.greenLight,
        opacity: 0.2,
        stroke: this.colors.green,
        strokeWidth: 3,
        dash: [20, 2]
      });

      this.layers.tmp = new Konva.Layer();
      this.stage.add(this.layers.tmp);

      this.layers.main = new Konva.Layer();
      this.shadowRectangle.hide();
      this.layers.main.add(this.shadowRectangle);

      if (this.pipelineStages.length === 0) {
        const queryStage = StageFactory.makeQueryStage(0);
        this.pipelineStages.push(queryStage);
      }

      let currentYPosition = 1;
      this.pipelineStages.forEach(stage => {
        let stageNode = this.createStageNode(
          { x: 1, y: currentYPosition },
          stage
        );

        this.layers.main.add(stageNode);
        this.nodes.push(stageNode);

        if (stage.config.subPipeline !== undefined) {
          let addNodePosition = { x: 50, y: -this.gridHeight };
          const addNode = this.createAddNode(addNodePosition, true);
          stageNode.add(addNode);
          addNode.moveToTop();

          const children = stage.config.subPipeline.sort((a, b) => {
            return b.order_index - a.order_index;
          });

          children.forEach((childStage, i) => {
            const position = { x: 50, y: (i + 2) * this.gridHeight * -1 };
            const childStageNode = this.createStageNode(position, childStage);
            stageNode.add(childStageNode);
          });

          stageNode.y(
            stageNode.y() +
              (stage.config.subPipeline.length + 1) * this.gridHeight
          );

          this.drawJoinGroupLines(stageNode);
        }

        const stageHeight =
          Math.round(stageNode.getClientRect().height / this.gridHeight) *
          this.gridHeight;
        currentYPosition += stageHeight;
      });

      const addShapePosition = { x: 1, y: currentYPosition };
      this.mainAddButton = this.createAddNode(addShapePosition);
      this.layers.main.add(this.mainAddButton);

      this.stage.add(this.layers.main);
      this.layers.main.draw();

      this.stage.on("click", e => {
        if (e.evt.button !== 2) {
          this.$refs.contextMenu.$el.style.display = "none";
        }
      });
      this.stage.on("contextmenu", e => {
        e.evt.preventDefault();
      });

      this.layers.lines = null;
      this.drawLines();

      if (isResize && this.selectedStage !== null) {
        this.setSelectedStage(this.selectedStage);
      } else {
        this.onNodeClick(this.nodes[0]);
      }

      this.checkStageSize();
    },
    createStageNode(position, pipelineStage, content = true) {
      let group = new Konva.Group({
        x: position.x,
        y: position.y,
        draggable: pipelineStage.type !== STAGE_TYPE_QUERY
      });
      group.setAttr("dataType", pipelineStage.type);
      group.setAttr("type", "pipelineStage");
      group.setAttr("stagePosition", pipelineStage.order_index);
      group.setAttr("movable", pipelineStage.type !== STAGE_TYPE_QUERY);
      group.setAttr("pipelineStage", pipelineStage);
      group.setAttr("id", pipelineStage.config.hash);

      const validResponse = DataPipelineValidation.validateStage(pipelineStage);

      this.addStatusImageToGroup(
        group,
        "statusSuccess",
        13,
        13,
        validResponse.isValid
      );
      this.addStatusImageToGroup(
        group,
        "statusError",
        13,
        13,
        !validResponse.isValid
      );

      let shape = createCircleShape(
        this.gridWidth,
        this.gridHeight,
        this.colors.black
      );
      group.add(shape);

      let shapeIconText = createCircleShapeIcon(
        shape,
        this.iconSize,
        getStageIcon(pipelineStage.type),
        this.colors.blue
      );
      group.add(shapeIconText);
      shapeIconText.moveToTop();

      shape.on("click", () => {
        this.onNodeClick(group);
      });
      shapeIconText.on("click", () => {
        this.onNodeClick(group);
      });

      if (content) {
        const contentShapeAbsX =
          shape.getAbsolutePosition().x + shape.width() + 16;
        let contentShapeX = shape.x() + shape.width() + 16;
        let contentShapeWidth = this.stage.width() - contentShapeAbsX - 16;
        let contentArrowShapeOffset = 0;
        if (pipelineStage.type === STAGE_TYPE_JOIN) {
          contentShapeX += this.gridWidth / 2;
          contentShapeWidth -= this.gridWidth / 2;
          contentArrowShapeOffset = this.gridWidth / 2;
        }
        let contentShape = new Konva.Rect({
          x: contentShapeX,
          y: shape.y(),
          height: shape.height() * 2 - 16,
          width: contentShapeWidth,
          fill: this.colors.gray,
          cornerRadius: 10
        });
        group.add(contentShape);

        contentShape.on("click", () => {
          this.onNodeClick(group);
        });

        let contentArrowShape = new Konva.Shape({
          fill: this.colors.gray,
          sceneFunc: (context, s) =>
            contentArrowShapeScene(context, s, shape, contentArrowShapeOffset)
        });
        group.add(contentArrowShape);
        contentArrowShape.moveToTop();

        let contentTitle = new Konva.Text({
          name: "contentTitle",
          x: contentShapeX + 14,
          y: shape.y() + 10,
          text: pipelineStage.config.title,
          height: 12,
          width: contentShape.width() - 50,
          fill: "#7c858f"
        });
        group.add(contentTitle);

        let contentDescription = new Konva.Text({
          name: "contentDescription",
          x: contentShapeX + 14,
          y: contentTitle.y() + contentTitle.height() + 2,
          text: pipelineStage.config.description,
          width:
            contentShape.width() -
            (pipelineStage.type !== STAGE_TYPE_ON ? 120 : 20),
          height: contentShape.height() - contentTitle.height() - 16
        });
        group.add(contentDescription);

        if (
          pipelineStage.order_index > 0 &&
          pipelineStage.type !== STAGE_TYPE_ON
        ) {
          let deleteIcon = new Konva.Text({
            x: contentShapeX + contentShape.width() - 26,
            y: shape.y() + 12,
            text: "\uf1f8",
            fontFamily: '"Font Awesome 6 Pro"'
          });
          group.add(deleteIcon);
          deleteIcon.moveToTop();

          deleteIcon.on("mouseenter", () => {
            this.stage.container().style.cursor = "pointer";
          });

          deleteIcon.on("mouseleave", () =>
            this.setStageCursor(false, pipelineStage.order_index)
          );

          deleteIcon.on("click", ev => {
            Swal.fire({
              title: this.$t("dataSets.deleteStageText", {
                name: pipelineStage.name
              }),
              icon: "warning",
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonColor: this.colors.primary,
              cancelButtonText: this.$t("general.cancel"),
              confirmButtonText: this.$t("general.delete")
            }).then(result => {
              if (!result.isConfirmed) {
                return;
              }
              this.onNodeDeleteClick(ev, pipelineStage);
            });
          });
        }

        if (pipelineStage.type !== STAGE_TYPE_ON) {
          const buttonTestStage = createKonvaButton(
            { x: 0, y: 0 },
            this.$t("dataSets.testStage"),
            this.colors.primary
          );
          group.add(buttonTestStage);

          buttonTestStage.x(
            contentShapeX + contentShape.width() - buttonTestStage.width() - 10
          );
          buttonTestStage.y(
            contentShape.y() +
              contentShape.height() -
              buttonTestStage.height() -
              10
          );

          buttonTestStage.on("mouseenter", () => {
            setButtonFill(buttonTestStage, this.colors.primaryDark);
            this.stage.container().style.cursor = "pointer";
          });

          buttonTestStage.on("mouseleave", () => {
            setButtonFill(buttonTestStage, this.colors.primary);
            this.setStageCursor(false, pipelineStage.order_index);
          });

          buttonTestStage.on("click", () => {
            this.onNodeClick(group);
            this.$emit("run", pipelineStage);
          });
        }
      }

      group.on("dragstart", this.onNodeDragStart);
      group.on("dragend", this.onNodeDragEnd);
      group.on("dragmove", this.onNodeDragMove);

      group.on("mouseenter", () =>
        this.setStageCursor(false, pipelineStage.order_index)
      );
      group.on("mouseleave", () => {
        this.stage.container().style.cursor = "default";
      });
      return group;
    },
    createAddNode(position, inJoin = false) {
      let node = new Konva.Group({
        x: position.x,
        y: position.y
      });
      node.setAttr("movable", false);
      node.type = "addShape";
      node.setAttr("id", "addGroup");

      let shape = new Konva.Shape({
        opacity: 1,
        type: "circle",
        width: this.gridWidth,
        height: this.gridHeight / 2,
        stroke: this.colors.black,
        strokeWidth: 1,
        fillPatternRepeat: "no-repeat",
        fillPriority: "pattern",
        sceneFunc: circleScene,
        shadowColor: this.colors.black,
        shadowBlur: 10,
        shadowOffset: { x: 0, y: 0 },
        shadowOpacity: 0.7,
        shadowEnabled: false
      });
      node.add(shape);

      node.on("mousedown", e => {
        e.evt.preventDefault();
        if (e.evt.button !== 0) {
          return;
        }
        this.addMenu.target = node;
        this.addMenu.inJoin = inJoin;

        this.$nextTick().then(() => {
          let menu = this.$refs.contextMenu.$el;
          menu.style.display = "initial";
          menu.style.top = this.stage.getPointerPosition().y + 6 + "px";
          menu.style.left = this.stage.getPointerPosition().x + 6 + "px";
        });
      });

      let shapeIconText = new Konva.Text({
        x: shape.x() + shape.width() / 2 - this.iconSize / 2,
        y: shape.y() + shape.height() / 2 - this.iconSize / 2,
        text: "\uf067",
        fontFamily: '"Font Awesome 6 Pro"',
        fontStyle: "bold",
        fill: "#3b7095",
        fontSize: this.iconSize
      });
      node.add(shapeIconText);

      node.on("mouseenter", () => this.setStageCursor(true, 0));
      node.on("mouseleave", () => {
        this.stage.container().style.cursor = "default";
      });
      return node;
    },

    addStatusImageToGroup(group, name, width, height, show) {
      Konva.Image.fromURL(
        name === "statusSuccess"
          ? require("../../../../assets/media/data_sets/circle-check-regular.png")
          : require("../../../../assets/media/data_sets/circle-exclamation-solid.png"),
        imageNode => {
          group.add(imageNode);
          imageNode.name = name;
          imageNode.setAttrs({
            x: this.gridWidth - width - 1,
            y: this.gridWidth - height - 1,
            width: width,
            height: height
          });
          if (!show) {
            imageNode.hide();
          }
        },
        error => {
          console.log("Image.fromURL error", error, name);
        }
      );
    },

    setNodeValidStatus(node, valid) {
      const statusIconSuccess = this.getStatusSuccess(node);
      const statusIconError = this.getStatusError(node);

      if (statusIconSuccess) {
        valid ? statusIconSuccess.show() : statusIconSuccess.hide();
      }
      if (statusIconError) {
        valid ? statusIconError.hide() : statusIconError.show();
      }
    },
    getStatusSuccess(node) {
      return node.children.find(c => c.name === "statusSuccess");
    },
    getStatusError(node) {
      return node.children.find(c => c.name === "statusError");
    },

    onNodeDeleteClick(ev, pipelineStage) {
      const target = ev.target.parent;
      const parent = target.parent;
      const inline = parent.getClassName() === "Group";
      const height =
        Math.round(target.getClientRect().height / this.gridHeight) *
        this.gridHeight;

      if (inline) {
        // move nodes in group
        let children = parent.getChildren(c => c.y() < target.y());
        children.forEach(c => {
          c.y(c.y() + this.gridHeight);
        });
        target.destroy();

        // move nodes in layer
        children = parent.parent.getChildren(
          c => c.y() > parent.getClientRect().y && c !== parent
        );
        children.forEach(c => {
          c.y(c.y() - this.gridHeight);
        });
        parent.y(parent.y() - this.gridHeight);
        this.drawJoinGroupLines(parent);

        const parentStage = this.pipelineStages.find(
          s => s.config.hash === pipelineStage.parentId
        );
        if (parentStage) {
          const stageIndex =
            parentStage.config.subPipeline.indexOf(pipelineStage);
          parentStage.config.subPipeline.splice(stageIndex, 1);
        }
      } else {
        let children = parent.getChildren(c => c.y() > target.y());
        children.forEach(c => {
          c.y(c.y() - height);
        });
        target.destroy();

        const stageIndex = this.pipelineStages.indexOf(pipelineStage);
        this.pipelineStages.splice(stageIndex, 1);
      }

      // set stagePosition
      let children = parent
        .getChildren(c => c.getClassName() === "Group")
        .sort((a, b) => {
          return a.y() - b.y();
        });
      children.forEach((c, i) => {
        c.setAttr("stagePosition", i);

        if (c.attrs.pipelineStage) {
          c.attrs.pipelineStage.order_index -= 1;
        }
      });

      this.drawLines();

      this.onNodeClick();
      this.stage.container().style.cursor = "default";
    },
    onNodeDragStart(ev) {
      this.$refs.contextMenu.$el.style.display = "none";
      this.onNodeClick(ev.target);

      this.lastPosition = {
        x: Math.round(ev.evt.offsetX / this.gridWidth) * this.gridWidth,
        y: Math.round(ev.evt.offsetY / this.gridHeight) * this.gridHeight
      };

      const inline = ev.target.parent.getClassName() === "Group";
      if (inline) {
        this.shadowRectangle.position({
          x: ev.target.getAbsolutePosition().x + this.gridWidth / 2,
          y: ev.target.getAbsolutePosition().y + this.gridWidth / 2
        });
        this.shadowRectangle.show();
        this.shadowRectangle.moveToBottom();
        ev.target.moveToTop();
        return;
      }
      this.originalPosition = {
        x: ev.target.x(),
        y: ev.target.y()
      };

      this.shadowRectangle.show();
      this.shadowRectangle.moveToTop();
      ev.target.moveToTop();

      this.lastElementChanged.element = null;
      this.lastElementChanged.up = null;
      this.setLastPosition(ev.target);
    },
    onNodeDragEnd(ev) {
      ev.target.y(this.lastPosition.y);
      this.shadowRectangle.hide();

      this.onNodeClick(ev.target);
    },
    onNodeDragMove(ev) {
      const inline = ev.target.parent.getClassName() === "Group";
      const originalX = inline ? 50 : 1;
      ev.target.x(originalX);

      if (inline) {
        const newAbsY =
          Math.round(ev.target.getAbsolutePosition().y / this.gridHeight) *
          this.gridHeight;
        const newY =
          Math.round(ev.target.y() / this.gridHeight) * this.gridHeight;

        let children = ev.target.parent.getChildren(
          c =>
            c.getClassName() === "Group" && c.attrs.movable && c !== ev.target
        );
        for (let child of children) {
          if (child === ev.target) continue;

          const childY =
            Math.round(child.y() / this.gridHeight) * this.gridHeight;
          if (newY === childY) {
            // change stagePosition
            const tmpPosition = child.attrs.stagePosition;
            child.setAttr("stagePosition", ev.target.attrs.stagePosition);
            ev.target.setAttr("stagePosition", tmpPosition);

            const tmpOrderIndex = child.attrs.pipelineStage.order_index;
            child.attrs.pipelineStage.order_index =
              ev.target.attrs.pipelineStage.order_index;
            ev.target.attrs.pipelineStage.order_index = tmpOrderIndex;

            child.y(this.lastPosition.y);
            break;
          }
        }
        const min = this.gridHeight * (children.length + 2) * -1;
        if (newY >= min && newY < -100) {
          this.shadowRectangle.position({
            x: originalX + this.gridWidth / 2,
            y: newAbsY + this.gridHeight / 4
          });
          this.lastPosition = { x: originalX, y: newY };
        }

        return;
      }

      const rect = ev.target.getClientRect();
      const height =
        Math.round(rect.height / this.gridHeight) * this.gridHeight;

      // find snap Position
      let position = rect.y + height - this.gridHeight;

      let nearestSnapPosition = this.lastPosition.y;
      let nearestChild = ev.target;

      let children = ev.target.parent.getChildren(
        c => c.getClassName() === "Group" && c.attrs.movable && c !== ev.target
      );
      for (let child of children) {
        const childRect = child.getClientRect();

        // get child height based on grid
        const childHeight =
          Math.round(childRect.height / this.gridHeight) * this.gridHeight;
        const childSnapPosition =
          Math.abs(
            Math.round(childRect.y / this.gridHeight) * this.gridHeight
          ) +
          childHeight -
          this.gridHeight;

        let childSnapPositionOffset = (childHeight - this.gridHeight) / 2;
        if (this.lastPosition.y > rect.y) {
          childSnapPositionOffset = childSnapPosition - childSnapPositionOffset;
        } else {
          childSnapPositionOffset += childSnapPosition;
        }

        const diff = Math.abs(position - childSnapPositionOffset);
        const nearestDiff = Math.abs(position - nearestSnapPosition);

        if (nearestSnapPosition === null || diff < nearestDiff) {
          nearestSnapPosition = childSnapPosition;
          nearestChild = child;
        }
      }
      const up = this.lastPosition.y > nearestSnapPosition;
      if (
        nearestChild !== ev.target &&
        (nearestChild !== this.lastElementChanged.element ||
          up !== this.lastElementChanged.up)
      ) {
        this.lastElementChanged.element = nearestChild;
        this.lastElementChanged.up = up;

        let childHeight =
          Math.round(nearestChild.getClientRect().height / this.gridHeight) *
          this.gridHeight;

        let tmp = this.lastPosition.y;
        this.setLastPosition(nearestChild);
        nearestChild.y(tmp);

        // change stagePosition
        const tmpPosition = nearestChild.attrs.stagePosition;
        nearestChild.setAttr("stagePosition", ev.target.attrs.stagePosition);
        ev.target.setAttr("stagePosition", tmpPosition);

        const tmpOrderIndex = nearestChild.attrs.pipelineStage.order_index;
        nearestChild.attrs.pipelineStage.order_index =
          ev.target.attrs.pipelineStage.order_index;
        ev.target.attrs.pipelineStage.order_index = tmpOrderIndex;

        if (up) {
          this.lastPosition.y -= childHeight - height;
        } else {
          nearestChild.y(nearestChild.y() + (childHeight - height));
        }
      }

      let shadowY = this.lastPosition.y + this.gridHeight / 4;
      this.shadowRectangle.position({
        x: originalX + this.gridWidth / 2,
        y: shadowY
      });

      this.stage.batchDraw();
    },
    onNodeClick(group = null) {
      this.resetShapeActiveState(this.layers.main.children);

      if (group === null) {
        this.selectStage(null, null);
        return;
      }

      const pipelineStage = group.attrs.pipelineStage;
      const parent = group.parent;
      const parentQuery = parent.children.find(
        c =>
          c.attrs.pipelineStage &&
          c.attrs.pipelineStage.type === STAGE_TYPE_QUERY
      );
      let dataStructureName = null;
      if (parentQuery) {
        dataStructureName = parentQuery.attrs.pipelineStage.config.model;
      }

      if (pipelineStage.type === STAGE_TYPE_JOIN) {
        pipelineStage.config.subPipeline.sort((a, b) => {
          return a.order_index - b.order_index;
        });
        const queryStage = pipelineStage.config.subPipeline.find(
          s => s.type === STAGE_TYPE_QUERY
        );
        if (queryStage) {
          dataStructureName = queryStage.config.model;
        }
      }

      const shapes = group.getChildren(
        c => c.getClassName() === "Shape" && c.attrs.type === "circle"
      );
      shapes.forEach(shape => {
        shape.stroke(this.colors.primary);
      });
      this.selectStage(pipelineStage, dataStructureName);

      this.stage.batchDraw();
    },

    selectStage(stage, dataStructureName) {
      this.selectedStage = stage;
      this.$emit("selectStage", stage, dataStructureName);
    },
    setSelectedStage(stage) {
      const node = this.getNodeToStage(stage);
      if (node) {
        this.onNodeClick(node);
      }
    },
    setStageValidationStatus(isValid, stage) {
      const node = this.getNodeToStage(stage);

      if (node) {
        this.setNodeValidStatus(node, isValid);
      }

      if (stage.parentId !== undefined && stage.parentId !== null) {
        const parentStage = this.pipelineStages.find(
          s => s.config.hash === stage.parentId
        );
        const parentNode = this.getNodeToStage(parentStage);
        let parentIsValid = true;
        parentNode.children.forEach(child => {
          if (child.attrs.pipelineStage === undefined) {
            return;
          }
          const validResponse = DataPipelineValidation.validateStage(
            child.attrs.pipelineStage
          );
          if (!validResponse.isValid) {
            parentIsValid = false;
          }
        });
        this.setNodeValidStatus(parentNode, parentIsValid);
      }
    },

    setContentDescription(stage) {
      const node = this.getNodeToStage(stage);

      if (node) {
        let contentTitle = this.getContentTitleNodeFromNode(node);
        if (contentTitle) {
          contentTitle.text(stage.config.title);
        }
        let contentDescription = this.getContentDescriptionNodeFromNode(node);
        if (contentDescription) {
          contentDescription.text(stage.config.description);
        }
      }
    },

    getContentTitleNodeFromNode(node) {
      return node.children.find(c => c.attrs.name === "contentTitle");
    },
    getContentDescriptionNodeFromNode(node) {
      return node.children.find(c => c.attrs.name === "contentDescription");
    },

    getNodeToStage(stage) {
      for (const node of this.nodes) {
        if (node.attrs.pipelineStage.config.hash === stage.config.hash) {
          return node;
        }

        if (node.attrs.pipelineStage.type === STAGE_TYPE_JOIN) {
          let children = node.getChildren(
            c =>
              c.getClassName() === "Group" &&
              c.attrs.pipelineStage !== undefined
          );
          for (const child of children) {
            if (child.attrs.pipelineStage.config.hash === stage.config.hash) {
              return child;
            }
          }
        }
      }
    },

    setLastPosition(element) {
      const rect = element.getClientRect();
      const height =
        Math.round(rect.height / this.gridHeight) * this.gridHeight;
      this.lastPosition.y =
        Math.round((rect.y + height - this.gridHeight) / this.gridHeight) *
        this.gridHeight;
    },
    setStageCursor(isAddShape, stagePosition) {
      let cursor = "default";
      if (isAddShape) {
        cursor = "pointer";
      } else if (stagePosition > 0) {
        cursor = "move";
      }
      this.stage.container().style.cursor = cursor;
    },

    drawLines() {
      if (this.layers.lines === null) {
        this.layers.lines = new Konva.Layer();
        this.stage.add(this.layers.lines);
        this.layers.lines.moveToBottom();
      }
      this.layers.lines.destroyChildren();

      let children = this.layers.main.children.filter(
        c => c.getClassName() === "Group"
      );
      children.sort((a, b) => {
        return a.y() - b.y();
      });

      for (let i = 0; i < children.length - 1; i++) {
        const child = children[i];

        const childX = 0;
        const childY = child.getClientRect().y + 1;
        const childHeight = child.getClientRect().height;

        let line = new Konva.Line({
          points: [
            childX + this.gridWidth / 2,
            childY,
            childX + this.gridWidth / 2,
            childY + Math.ceil(childHeight / this.gridHeight) * this.gridHeight
          ],
          stroke: this.colors.black,
          strokeWidth: 1
        });
        this.layers.lines.add(line);
      }
    },
    drawJoinGroupLines(joinNode) {
      let lines = joinNode.getChildren(c => c.getClassName() === "Line");
      for (let i = 0; i < lines.length; i++) {
        lines[i].destroy();
      }

      const children = joinNode
        .getChildren(c => c.getClassName() === "Group")
        .sort((a, b) => {
          return a.y() - b.y();
        });
      children.forEach((child, childIndex) => {
        const childX = child.x();
        const childY = child.y();

        const line = new Konva.Line({
          points: [
            childX + this.gridWidth / 2,
            childY,
            childX + this.gridWidth / 2,
            childY +
              this.gridHeight * (childIndex === children.length - 1 ? 1.25 : 1)
          ],
          stroke: this.colors.black,
          strokeWidth: 1
        });
        joinNode.add(line);
        line.moveToBottom();

        if (childIndex === children.length - 1) {
          const line = new Konva.Line({
            points: [
              childX,
              childY + this.gridHeight * 1.25,
              childX + this.gridWidth / 2,
              childY + this.gridHeight * 1.25
            ],
            stroke: this.colors.black,
            strokeWidth: 1
          });
          joinNode.add(line);
          line.moveToBottom();
        }
      });
    },

    addNewJoin() {
      let joinStage = StageFactory.makeJoinStage(this.pipelineStages.length);
      this.pipelineStages.push(joinStage);

      let joinNodePosition = {
        x: this.mainAddButton.x(),
        y: this.mainAddButton.y() + 3 * this.gridHeight
      };
      const joinNode = this.createStageNode(joinNodePosition, joinStage);
      this.layers.main.add(joinNode);
      this.nodes.push(joinNode);

      let addNodePosition = { x: 50, y: -this.gridHeight };
      const addNode = this.createAddNode(addNodePosition, true);
      joinNode.add(addNode);
      addNode.moveToTop();

      let onNodePosition = {
        x: addNodePosition.x,
        y: addNodePosition.y - this.gridHeight
      };
      const onPipelineStage = StageFactory.makeOnStage(
        1,
        joinStage.config.hash
      );
      joinStage.config.subPipeline.push(onPipelineStage);
      const onNode = this.createStageNode(onNodePosition, onPipelineStage);
      joinNode.add(onNode);

      let queryNodePosition = {
        x: onNodePosition.x,
        y: onNodePosition.y - this.gridHeight
      };
      const queryPipelineStage = StageFactory.makeQueryStage(
        0,
        joinStage.config.hash
      );
      joinStage.config.subPipeline.push(queryPipelineStage);
      const queryNode = this.createStageNode(
        queryNodePosition,
        queryPipelineStage
      );
      joinNode.add(queryNode);

      this.mainAddButton.y(joinNodePosition.y + this.gridHeight);
      this.checkStageSize();

      this.drawLines();
      this.drawJoinGroupLines(joinNode);

      // select query node
      this.onNodeClick(queryNode);
    },
    addFilter() {
      const filterStage = StageFactory.makeFilterStage(
        this.pipelineStages.length
      );
      this.addSimpleStage(filterStage);
    },
    addAggregation() {
      const aggregationStage = StageFactory.makeAggregationStage(
        this.pipelineStages.length
      );
      this.addSimpleStage(aggregationStage);
    },
    addSimpleStage(pipelineStage) {
      let stagePosition = {
        x: this.addMenu.target.x(),
        y: this.addMenu.target.y()
      };
      let orderIndex = this.nodes.length;
      if (this.addMenu.inJoin) {
        // move stages on join level to top
        let children = this.addMenu.target.parent
          .getChildren(c => c.getClassName() === "Group")
          .sort((a, b) => {
            return a.y() - b.y();
          });
        children.forEach(child => {
          child.y(child.y() - this.gridHeight);
        });
        this.addMenu.target.parent.y(
          this.addMenu.target.parent.y() + this.gridHeight
        );

        stagePosition.y = stagePosition.y - this.gridHeight;
        orderIndex = children.length - 1;

        // move stages on first level to bottom
        this.nodes
          .filter(
            s =>
              s.getClientRect().y > this.addMenu.target.parent.getClientRect().y
          )
          .forEach(c => {
            c.y(c.y() + this.gridHeight);
          });
      }

      const stageGroup = this.createStageNode(
        stagePosition,
        pipelineStage,
        orderIndex
      );
      this.addMenu.target.parent.add(stageGroup);

      if (this.addMenu.inJoin) {
        this.addMenu.target.y(stagePosition.y + this.gridHeight);
        this.drawJoinGroupLines(this.addMenu.target.parent);

        let parentStage = this.addMenu.target.parent.attrs.pipelineStage;
        pipelineStage.order_index = parentStage.config.subPipeline.length;
        parentStage.config.subPipeline.push(pipelineStage);
        pipelineStage.parentId = parentStage.config.hash;
      } else {
        this.nodes.push(stageGroup);
        this.pipelineStages.push(pipelineStage);
      }
      this.mainAddButton.y(this.mainAddButton.y() + this.gridHeight);
      this.checkStageSize();

      this.drawLines();

      // select stage
      this.onNodeClick(stageGroup);
    },

    checkStageSize() {
      if (this.mainAddButton.y() + this.gridHeight > this.stage.height()) {
        this.stage.height(this.mainAddButton.y() + this.gridHeight);
      }
    },
    resetShapeActiveState(children) {
      children.forEach(child => {
        if (child.getClassName() !== "Group") {
          return;
        }
        const shapes = child.getChildren(
          c => c.getClassName() === "Shape" && c.attrs.type === "circle"
        );
        shapes.forEach(shape => {
          shape.stroke(this.colors.black);
        });

        this.resetShapeActiveState(child.getChildren());
      });
    }
  }
};
</script>

<style scoped></style>
