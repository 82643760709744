<template>
  <div>
    <div class="row mb-2">
      <div class="col-auto m-auto">
        <h3 v-if="stage" class="mb-0">{{ stage.name }}</h3>
      </div>
    </div>

    <b-tabs v-if="stage" :value="1">
      <b-tab :title="$t('dataSets.description')" class="p-3">
        <div class="row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("dataSets.title") }}
          </label>
          <div class="col">
            <input
              v-model.trim="stage.config.title"
              class="form-control"
              @input="onDescriptionChanged"
            />
          </div>
        </div>
        <div class="row align-items-top">
          <label class="col-xl-3 col-lg-3 col-form-label mt-1">
            {{ $t("dataSets.description") }}
          </label>
          <div class="col">
            <textarea
              v-model.trim="stage.config.description"
              class="form-control"
              rows="5"
              @input="onDescriptionChanged"
            />
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('dataSets.configuration')" class="p-3">
        <QueryConfig
          v-if="stage.type === 0"
          :key="configKey"
          :stage="stage"
          :data-structures="dataStructures"
          :data-structure-service="dataStructureService"
          :pipeline-stages="pipelineStages"
          @validate="onValidate"
          @updateBusyState="onUpdateBusyState"
        />
        <FilterConfig
          v-else-if="stage.type === 2"
          :key="configKey"
          :stage="stage"
          :sub-data-structures="subDataStructures"
          @validate="onValidate"
        />
        <OnConfig
          v-else-if="stage.type === 5"
          :key="configKey"
          :stage="stage"
          @validate="onValidate"
        />
        <AggregationConfig
          v-else-if="stage.type === 3"
          :key="configKey"
          :stage="stage"
          :sub-data-structures="subDataStructures"
          @validate="onValidate"
        />
        <JoinConfig
          v-else-if="stage.type === 4"
          :key="configKey"
          :stage="stage"
          :data-structures="dataStructures"
          :data-structure-service="dataStructureService"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AggregationConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/AggregationConfig";
import FilterConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/FilterConfig";
import JoinConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/JoinConfig";
import OnConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/OnConfig";
import QueryConfig from "@/components/Projects/DataSets/Editor/StageConfig/Components/QueryConfig";

import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";

export default {
  components: {
    AggregationConfig,
    FilterConfig,
    JoinConfig,
    OnConfig,
    QueryConfig
  },
  props: {
    stage: {
      type: Object,
      default: null
    },
    pipelineStages: {
      type: Array,
      default: () => []
    },
    updateKey: {
      type: Number,
      default: 0
    },
    subDataStructures: {
      type: Array,
      default: () => []
    },
    dataStructures: {
      type: Array,
      default: () => []
    },
    dataStructureService: DataStructures
  },
  data() {
    return {
      configKey: 0
    };
  },
  watch: {
    stage: function() {
      if (this.stage === null) return;
      if (this.stage.config.title === undefined) {
        this.stage.config.title = this.stage.name;
      }
      if (this.stage.config.description === undefined) {
        this.stage.config.description = "";
      }

      this.$nextTick().then(() => {
        this.configKey++;
      });
    }
  },
  methods: {
    onValidate(isValid, stage) {
      this.$emit("validate", isValid, stage);
    },
    onDescriptionChanged() {
      this.$emit("descriptionChanged", this.stage);
    },
    onUpdateBusyState(data) {
      this.$emit("updateBusyState", data);
    }
  }
};
</script>

<style lang="scss"></style>
