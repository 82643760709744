<template>
  <div style="margin-left: 10px">
    <div class="row">
      <div class="col py-1 mt-2">
        <h5>{{ $t("dataSets.filter") }}</h5>
      </div>
    </div>

    <FilterComponent
      v-if="dataStructure"
      :group="stage.config.filter"
      :data-structure="dataStructure"
      :sub-data-structures="subDataStructures"
      :operators="operatorGroups"
      @validate="onValidate"
    />
    <span v-else class="text-danger">
      {{ $t("dataSets.noDataStructureSelected") }}
    </span>
  </div>
</template>

<script>
import FilterComponent from "@/components/Tools/FilterComponent/Index";
import { mapGetters } from "vuex";

export default {
  components: {
    FilterComponent
  },
  props: {
    stage: {
      type: Object,
      default: null
    },
    subDataStructures: {
      type: Array,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      dataStructure: "selectedDataStructure",
      operatorGroups: "dataPipelineOperatorGroups"
    })
  },
  methods: {
    onValidate() {
      this.stage.isValid = this.stage.config.filter.valid;
      this.$emit("validate", this.stage.config.filter.valid, this.stage);
    }
  }
};
</script>
