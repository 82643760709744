<template>
  <ul
    ref="menu"
    class="nav flex-column position-absolute bg-white pl-0 border"
    style="z-index: 999"
  >
    <li v-if="showAddJoin" class="nav-item border-bottom">
      <a class="nav-link" href="#" @click.prevent="menuItemClick('addJoin')">
        {{ $t("dataSets.addJoin") }}
      </a>
    </li>
    <li class="nav-item border-bottom">
      <a class="nav-link" href="#" @click.prevent="menuItemClick('addFilter')">
        {{ $t("dataSets.addFilter") }}
      </a>
    </li>
    <li class="nav-item border-bottom">
      <a
        class="nav-link"
        href="#"
        @click.prevent="menuItemClick('addAggregation')"
      >
        {{ $t("dataSets.addAggregation") }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    showAddJoin: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    menuItemClick(event) {
      this.$refs.menu.style.display = "none";
      this.$emit(event);
    }
  }
};
</script>

<style scoped></style>
