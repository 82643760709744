<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ dataPipeline.name }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="
            !isModal
              ? $router.push({ name: 'projectDataSets' })
              : $emit('closeModal')
          "
        >
          {{ $t("general.cancel") }}
        </button>

        <button class="btn btn-primary" @click="save">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <v-progress-linear
      v-if="
        isBusy > 0 || dataSetStoreIsBusyInitial > 0 || dataSetStoreIsBusy > 0
      "
      indeterminate
      color="primary"
    />

    <div class="card-body px-0">
      <div class="row px-6">
        <div
          class="col-5"
          :class="dataPipeline.pipelineStages.length === 0 ? 'pt-0 pb-9' : ''"
        >
          <StageView
            v-if="dataPipeline.pipelineStages.length > 0"
            ref="stageView"
            :pipeline-stages="dataPipeline.pipelineStages"
            @selectStage="onSelectStage"
            @run="run"
          />
        </div>
        <v-divider vertical />
        <div class="col">
          <StageConfig
            :stage="selectedStage"
            :pipeline-stages="dataPipeline.pipelineStages"
            :sub-data-structures="selectedSubDataStructures"
            :data-structures="dataStructures"
            :data-structure-service="dataStructureService"
            @validate="onValidate"
            @descriptionChanged="onDescriptionChanged"
            @updateBusyState="onUpdateBusyState"
          />
        </div>
      </div>

      <div
        class="row"
        :class="dataPipeline.pipelineStages.length === 0 ? 'mt-16' : 'mt-5'"
      >
        <div class="col">
          <b-tabs v-model="tabIndex">
            <b-tab :title="$t('dataSets.preview')" class="px-6 py-3">
              <Preview
                v-if="dataPipeline.pipelineStages.length > 0"
                :data="lastPreviewData"
                @limitChanged="onLimitChanged"
              />
            </b-tab>
            <b-tab :title="$t('dataSets.parameter')" class="px-6 py-3">
              <Variables
                v-if="dataPipeline.pipelineStages.length > 0"
                :data="dataPipeline.variables"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataPipelines from "@/components/Projects/DataSets/dataPipelines";

import StageConfig from "@/components/Projects/DataSets/Editor/StageConfig/Index";

import Preview from "@/components/Projects/DataSets/Editor/Components/Preview";
import Variables from "@/components/Projects/DataSets/Editor/Components/Variables";

import StageView from "@/components/Projects/DataSets/Editor/StageView";
import DataPipelineValidation from "@/components/Projects/DataSets/dataPipelineValidation";

import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  LOAD_DATA_PIPELINE_CONFIG,
  LOAD_DATA_STRUCTURES,
  SET_MAIN_DATA_STRUCTURE,
  SET_SELECTED_DATA_STRUCTURE,
  STAGE_TYPE_JOIN,
  STAGE_TYPE_ON,
  STAGE_TYPE_QUERY,
  LOAD_CONFIG_VALUES,
  LOAD_PARAMETER_VALUE
} from "@/core/services/store/dataSets.module";
import { mapGetters } from "vuex";
import { cloneDeep } from "@/components/Tools/helperFunctions";
import Config from "@/components/Projects/Settings/Config/config";

const DataStructureService = new DataStructures();

export default {
  components: {
    Variables,
    Preview,
    StageConfig,
    StageView
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: 0,
      dataPipeline: {
        pipelineStages: []
      },
      selectedStage: null,
      selectedSubDataStructures: [],
      dataStructureService: DataStructureService,

      lastPreviewData: [],
      tabIndex: 0,
      limit: 50
    };
  },
  computed: {
    ...mapGetters({
      dataStructures: "dataStructures",
      dataSetStoreIsBusyInitial: "isBusyInitial",
      dataSetStoreIsBusy: "isBusy",
      selectedDataStructure: "selectedDataStructure"
    })
  },
  watch: {
    dataSetStoreIsBusyInitial: function(value) {
      if (!value) {
        this.initDataPipeline();
      }
    }
  },
  mounted() {
    this.setBreadcrumb();
    this.$store.dispatch(LOAD_PARAMETER_VALUE);
    this.$store.dispatch(SET_SELECTED_DATA_STRUCTURE, null);
    this.$store.dispatch(SET_MAIN_DATA_STRUCTURE, null);
    this.$store.dispatch(LOAD_DATA_STRUCTURES, DataStructureService);
    this.$store.dispatch(LOAD_CONFIG_VALUES, Config);
    this.$store.dispatch(LOAD_DATA_PIPELINE_CONFIG, DataPipelines);
  },
  methods: {
    loadVariables() {
      if (
        this.dataPipeline.variables === undefined ||
        this.dataPipeline.variables === null
      ) {
        return;
      }
      this.dataPipeline.variables.forEach(vars => {
        this.$store.dispatch(LOAD_PARAMETER_VALUE, vars);
      });
    },
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("dataSets.dataSets"),
          route: ""
        },
        {
          title: this.$t("dataSets.editor"),
          route: "/project/dataStore/editor"
        }
      ]);
    },
    loadDataPipeline(id) {
      this.isBusy++;
      DataPipelines.get(id)
        .then(response => {
          this.dataPipeline = response.data.data;
          this.loadVariables();

          for (const stage of this.dataPipeline.pipelineStages) {
            this.setPipelineStageData(stage);
          }

          if (this.dataPipeline.pipelineStages.length > 0) {
            this.selectedStage = this.dataPipeline.pipelineStages[0];
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isBusy--;
        });
    },
    setPipelineStageData(stage) {
      if (stage.type === STAGE_TYPE_QUERY) {
        let newFields = [];
        Object.keys(stage.config.fields).forEach(fieldAlias => {
          newFields.push({
            alias: fieldAlias,
            field:
              stage.config.alias === undefined
                ? stage.config.fields[fieldAlias]
                : stage.config.fields[fieldAlias].replace(
                    stage.config.alias + ".",
                    ""
                  )
          });
        });
        stage.config.fields = newFields;
      } else if (stage.type === STAGE_TYPE_JOIN) {
        stage.config.subPipeline.forEach(subStage => {
          this.setPipelineStageData(subStage);
        });
      } else if (stage.type === STAGE_TYPE_ON) {
        let localField = stage.config.aliases[stage.config.localField];
        stage.config.localField = localField.replace("$", "");
      }
    },
    initDataPipeline() {
      if (this.isModal) {
        this.dataPipeline = this.$store.getters.dataPipelineCreated;
        return;
      }

      const id = this.$route.params.id;
      if (id) {
        this.loadDataPipeline(id);
        return;
      }

      this.dataPipeline = this.$store.getters.dataPipelineCreated;
    },
    async onSelectStage(stage, dataStructureName) {
      let queryStage = null;
      if (stage !== null) {
        queryStage = this.getQueryStage(stage);
      }

      await this.$store.dispatch(SET_SELECTED_DATA_STRUCTURE, {
        classname: dataStructureName,
        dataStructureService: this.dataStructureService,
        queryStage: queryStage
      });

      this.selectedSubDataStructures = [];
      if (
        stage === null ||
        (stage.parentId !== undefined && stage.parentId !== null)
      ) {
        this.selectedStage = stage;
        return;
      }

      await this.setSelectedSubDataStructures(stage);
      this.selectedStage = stage;
    },
    onValidate(isValid, stage) {
      this.$nextTick().then(() => {
        if (this.$refs.stageView === undefined) return;
        this.$refs.stageView.setStageValidationStatus(isValid, stage);
      });
    },
    onDescriptionChanged(stage) {
      this.$nextTick().then(() => {
        if (this.$refs.stageView === undefined) return;
        this.$refs.stageView.setContentDescription(stage);
      });
    },
    onUpdateBusyState(data) {
      this.isBusy += data;
    },

    async setSelectedSubDataStructures(stage) {
      // get join stages before current stage
      const joinStages = this.dataPipeline.pipelineStages.filter(
        s => s.type === STAGE_TYPE_JOIN && s.order_index < stage.order_index
      );
      for (const joinStage of joinStages) {
        const queryStage = joinStage.config.subPipeline.find(
          s => s.type === STAGE_TYPE_QUERY
        );
        const dataStructure = this.dataStructures.find(
          ds => ds.classname === queryStage.config.model
        );
        if (!dataStructure) {
          continue;
        }

        if (dataStructure.fields === undefined) {
          this.isBusy++;
          await this.dataStructureService
            .get(dataStructure.id)
            .then(data => {
              dataStructure.fields = data.data.data.fields;
            })
            .finally(() => {
              this.isBusy--;
            });
        }

        let subDataStructure = {
          classname: dataStructure.classname,
          fields: []
        };

        queryStage.config.fields.forEach(queryField => {
          const dsField = dataStructure.fields.find(
            f => f.full_name === queryField.field
          );
          dsField.alias = queryField.alias;
          subDataStructure.fields.push(dsField);
        });
        this.selectedSubDataStructures.push(subDataStructure);
      }
    },

    getQueryStage(stage) {
      let stages = this.dataPipeline.pipelineStages;
      if (stage.parentId !== undefined && stage.parentId !== null) {
        const joinStage = this.dataPipeline.pipelineStages.find(
          s => s.config.hash === stage.parentId
        );
        stages = joinStage.config.subPipeline;
      }
      return stages.find(s => s.type === STAGE_TYPE_QUERY);
    },

    setOrderIndex() {
      this.dataPipeline.pipelineStages.forEach((stage, index) => {
        stage.order_index = index;
      });
    },

    selectStage(stage) {
      this.$nextTick().then(() => {
        if (this.$refs.stageView === undefined) return;
        this.$refs.stageView.setSelectedStage(stage);
      });
    },

    onLimitChanged(limit) {
      this.limit = limit;
    },

    getStageConfig(stage) {
      let config = {
        type: stage.type,
        name: stage.name,
        active: true,
        order_index: stage.order_index
      };
      config.config = cloneDeep(stage.config);

      if (stage.type === STAGE_TYPE_QUERY) {
        let fields = {};
        config.config.fields.forEach(field => {
          const alias =
            field.alias.trim().length > 0 ? field.alias : field.field;
          fields[alias] = field.field;
        });
        config.config.fields = fields;
      }

      if (stage.type !== STAGE_TYPE_JOIN) {
        return config;
      }
      config.config.subPipeline = config.config.subPipeline.sort((a, b) => {
        return a.order_index - b.order_index;
      });

      config.config.subPipeline.forEach(subStage => {
        if (subStage.type === STAGE_TYPE_ON) {
          subStage.config.aliases = {};
          const alias = subStage.config.localField.replace(".", "");
          subStage.config.aliases[alias] = "$" + subStage.config.localField;
          subStage.config.localField = alias;
        } else if (subStage.type === STAGE_TYPE_QUERY) {
          let fields = {};
          subStage.config.fields.forEach(field => {
            fields[field.alias] = subStage.config.alias + "." + field.field;
          });
          subStage.config.fields = fields;
        }
      });

      return config;
    },
    run(stage) {
      this.setOrderIndex();
      let data = {
        pipelineStages: []
      };

      let stages = this.dataPipeline.pipelineStages;
      if (stage.parentId !== undefined && stage.parentId !== null) {
        stages = this.dataPipeline.pipelineStages
          .find(s => s.config.hash === stage.parentId)
          .config.subPipeline.filter(s => s.type !== STAGE_TYPE_ON);
        // if we run a subpipeline, we can ignore the on-stage
      }

      let stagesSorted = stages.sort((a, b) => {
        return a.order_index - b.order_index;
      });

      for (const s of stagesSorted) {
        const validResponse = DataPipelineValidation.validateStage(s);
        if (!validResponse.isValid) {
          this.selectStage(validResponse.stage);
          this.showErrorToast(this.$t(validResponse.errorMessage));
          return;
        }
        const stageConfig = this.getStageConfig(s);
        data.pipelineStages.push(stageConfig);
        if (s === stage) {
          break;
        }
      }

      if (data.pipelineStages.length === 0) {
        return;
      }
      data.limit = this.limit;
      data.variables = this.$store.state.dataSets.parameterValues;
      this.isBusy++;
      this.lastPreviewData = [];
      DataPipelines.run(data)
        .then(response => {
          this.lastPreviewData = response.data.data;
          this.isBusy--;
          this.tabIndex = 0;
        })
        .catch(error => {
          console.log(error);
          this.isBusy--;
        });
    },
    save() {
      const validResponse = DataPipelineValidation.validateDataPipeline(
        this.dataPipeline
      );
      if (!validResponse.isValid) {
        this.selectStage(validResponse.stage);
        this.showErrorToast(this.$t(validResponse.errorMessage));
        return;
      }

      this.isBusy++;
      this.setOrderIndex();

      const data = {
        name: this.dataPipeline.name,
        active: this.dataPipeline.active,
        variables: this.$store.state.dataSets.parameterValues,
        pipelineStages: []
      };
      for (const stage of this.dataPipeline.pipelineStages) {
        data.pipelineStages.push(this.getStageConfig(stage));
      }

      if (this.dataPipeline.id) {
        this.updateDataPipeline(this.dataPipeline.id, data);
        return;
      }
      this.createDataPipeline(data);
    },
    createDataPipeline(data) {
      DataPipelines.store(data)
        .then(response => {
          this.dataPipeline.id = response.data.data.id;
          this.$toast.fire({
            icon: "success",
            title: this.$t("dataSets.savedSuccess")
          });

          if (this.isModal) {
            this.$emit("closeModal");
            return;
          }

          this.$router.push({
            name: "projectDataSetsEditor",
            params: { id: this.dataPipeline.id }
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isBusy--;
        });
    },
    updateDataPipeline(id, data) {
      DataPipelines.update(id, data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("dataSets.savedSuccess")
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isBusy--;
        });
    },
    showErrorToast(message) {
      this.$toast.fire({
        icon: "error",
        title: message
      });
    }
  }
};
</script>
